import { gql } from "@apollo/client"

export const GET_PRICELISTS = gql`
  query GET_PRICELISTS(
    $searchTerm: String
    $take: Int
    $skip: Int
    $orderBy: [PriceListOrderByWithRelationInput!]
  ) {
    findManyPriceListCount(
      where: {
        OR: [
          { name: { mode: insensitive, contains: $searchTerm } }
        ]
      }
    )

    findManyPriceList(
      orderBy: $orderBy
      where: {
        OR: [
          { name: { mode: insensitive, contains: $searchTerm } }
        ]
      }
      take: $take
      skip: $skip
    ) {
      id
      createdAt
      name
    }
  }
`

export const DELETE_SINGLE_PRICELIST = gql`
  mutation DELETE_SINGLE_PRICELIST($id: Int!) {
    deleteOnePriceList(where: { id: $id }) {
      id
  }
  }
`

export const CORE_DATA = gql`
  fragment CoreData on PriceList {
    id
    createdAt
    updatedAt
    default
    name
  }
`

//  TODO ======
export const CREATE_PRICELIST = gql`
  ${CORE_DATA}
mutation CreateOnePriceList($name:String!) {
  createOnePriceList(data: {
    name: $name
  }) {
    ...CoreData
  }
}

`

export const UPDATE_PRICELIST = gql`
  ${CORE_DATA}
  mutation UPDATE_PRICELIST(
    $where: PriceListWhereUniqueInput!
    $name: String
  ) {
    updateOnePriceList(
      where: $where
      data: {
        name: {
          set: $name
        }
      }
    ) {
      ...CoreData
    }
  }
`

export const GET_PRICELIST = gql`
  ${CORE_DATA}
  query GET_PRICELIST($id: Int!) {
    findUniquePriceList(where: { id: $id }) {
       ...CoreData
    }
  }
`

export const GET_PRODUCTS_FOR_PRICELIST = gql`
query GET_PRODUCTS(
  $id: Int
  $language: Language
) {
    findManyProductContainer( where: { advanced_product: {
      is : {
        OR: [{
          base_prices : {
            some: { 
              price_listId: {equals:  $id}
            }
          }
        }
        {
          advanced_product_price_fabric_category : {
            some: { 
              price_listId: {equals:  $id}
            }
          }
        }
        {
          sofa_forms: {
            some: {
             form_price_fabric_category :{
               some: {
                 price_listId: {equals:  $id}
               }
             }
            }
          }
        }]

      }
    } }) {
      id
      type
      single_product {
        product_profiles ( where: { language: { equals:$language}} ) {
          name
        }
      }
      advanced_product {
        advanced_product_type
        advanced_product_profiles( where: { language: { equals:$language}} ) {
          name
        }
        base_prices(where: {
          price_listId: {
            equals: $id
          }
        }) {
          price
          price_from
          price_listId
        }
        advanced_product_price_fabric_category(where: {
            price_listId :{
              equals: $id
            }
          }){
          price
          price_listId
          fabrice_price_category {
            group_number
          }
        }
        sofa_forms {
          id
          name
          form_price_fabric_category(where: {
            price_listId :{
              equals: $id
            }
          }) {
            price
            price_listId
            fabrice_price_category {
              group_number
            }
          }
        }
      }
    }
  }
`