import React, { useCallback, useEffect, useState } from 'react'

import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalButton,
  SIZE,
  ROLE,
} from 'baseui/modal'
import { KIND as ButtonKind } from 'baseui/button'
import { styled } from 'baseui'
import { Select, Value } from 'baseui/select'
import { expandBorderStyles } from 'baseui/styles'
import DrawerSelect from 'components/Select/DrawerSelect'
import Checkbox, {
  LABEL_PLACEMENT,
  STYLE_TYPE,
} from 'components/CheckBox/CheckBox'
import MultipleCategorySelect from 'components/CategorySelect/MultipleCategorySelect'

export interface ConfirmationOptions {
  catchOnCancel?: boolean
  variant: 'danger' | 'info'
  title: string
  description: string
  selectionOptions?: Array<selectionOptions> | undefined | null
}

interface ConfirmationDialogProps extends ConfirmationOptions {
  open: boolean
  onSubmit: (value) => any
  onClose: () => void
}

interface selectionOptions {
  id: number
  label: string
  color: string
}

export const CategorySelectModal: React.FC<ConfirmationDialogProps> = ({
  open,
  title,
  variant,
  description,
  selectionOptions,
  onSubmit,
  onClose,
}) => {
  // console.log("We have selection options ->", selectionOptions);
  const [value, setValue] = useState(null)

  const [selectedCategories, setSelectedCategories] = useState([])

  // Reset modal value...
  useEffect(() => {
    setValue(null)
  }, [open])

  useEffect(() => {
    setValue(selectedCategories)
  }, [selectedCategories])

  const handleCategoryOnChange = (newValue) => {
    //---
    // console.log('Handling category select')
    // console.log('newValue :>> ', newValue)
    setSelectedCategories(newValue)
  }

  return (
    <Modal
      onClose={onClose}
      closeable
      isOpen={open}
      animate
      autoFocus
      size={SIZE.default}
      role={ROLE.dialog}
      overrides={{
        Root: {
          style: {
            zIndex: 100,
          },
        },
      }}
    >
      <ModalHeader>{title}</ModalHeader>
      <ModalBody>
        <p>{description}</p>

        <div
          style={{
            overflow: 'scroll',
            border: '1px solid #eee',
          }}
        >
          <MultipleCategorySelect
            onChange={(newValue) => handleCategoryOnChange(newValue)}
            value={selectedCategories}
            productContainerId={null}
            primaryCategoryId={null}
          />
        </div>
      </ModalBody>
      <ModalFooter>
        {variant === 'danger' && (
          <>
            <ModalButton
              kind={ButtonKind.tertiary}
              onClick={onClose}
              overrides={{
                BaseButton: {
                  style: ({ $theme }) => ({
                    color: $theme.colors.red400,
                  }),
                },
              }}
            >
              Cancel
            </ModalButton>
            <ModalButton
              onClick={(e) => onSubmit(value)}
              disabled={!value} // IF selection component and no value, then disabled...
              overrides={{
                BaseButton: {
                  style: ({ $theme }) => ({
                    backgroundColor: $theme.colors.red400,
                  }),
                },
              }}
            >
              Yes
            </ModalButton>
          </>
        )}

        {variant === 'info' && (
          <ModalButton
            disabled={!selectedCategories.length}
            onClick={(e) => onSubmit(value)}
          >
            OK
          </ModalButton>
        )}
      </ModalFooter>
    </Modal>
  )
}

// ===== Adding colors to selector ===
const ColorSwatch = styled('div', (props: any) => {
  return {
    width: props.$theme.sizing.scale500,
    height: props.$theme.sizing.scale500,
    marginRight: props.$theme.sizing.scale200,
    display: 'inline-block',
    backgroundColor: props.$color,
    verticalAlign: 'baseline',
    ...expandBorderStyles(props.$theme.borders.border400),
  }
})
const getLabel = ({ option }: any) => {
  return (
    <React.Fragment>
      {option.color ? <ColorSwatch $color={option.color} /> : null}
      {option.label}
    </React.Fragment>
  )
}
