import { gql, useMutation, useQuery } from '@apollo/client'
import { styled } from 'baseui'
import Button, { KIND } from 'components/Button/Button'
import { Col, Row } from 'components/FlexBox/FlexBox'
import { InLineLoader } from 'components/InlineLoader/InlineLoader'
import Input from 'components/Input/Input'
import React, { useState } from 'react'
import { Notification } from 'baseui/notification'

import { AiOutlineCopy } from 'react-icons/ai'
import { OrderDateComponent } from 'components/OrderDate/OrderDate'
import { Check } from 'baseui/icon'
import { DURATION, useSnackbar } from 'baseui/snackbar'
import { useConfirmation } from 'context/ConfirmationServiceContext'

const BoxWrapper = styled('div', ({ $theme }) => ({
  padding: '20px',
  boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
  background: '#fff',
  marginBottom: '30px',
  minHeight: '100px',
}))
const BoxTitle = styled('h2', ({ $theme }) => ({
  ...$theme.typography.font18,
  margin: 0,
  padding: '10px 0',
  borderBottom: '1px solid rgb(0 0 0 / 12%)',
  marginBottom: '10px',
}))

const NotificationWrapper = styled('div', ({ $theme }) => ({
  margin: 0,
  position: 'relative',
}))

const NotificationBody = styled('div', ({ $theme }) => ({
  position: 'absolute',
  bottom: '30px',
  width: '100%',
  display: 'flex',
  justifyContent: 'flex-end',
}))

export const GET_ALL_FACEBOOK_FEEDS = gql`
  query GET_ALL_FACEBOOK_FEEDS {
    findManyFacebookFeedResult(orderBy: { id: asc }) {
      # (orderBy: { updatedAt: desc })  # if we want to order by date
      id
      createdAt
      updatedAt
      name
      url
    }
  }
`

export const DELETE_ONE_FACEBOOK_FEED = gql`
  mutation DELETE_ONE_FACEBOOK_FEED($id: Int!) {
    deleteOneFacebookFeedResult(where: { id: $id }) {
      id
    }
  }
`

export const FacebookFeedsBox = ({ regenerateFunction, mutationLoading }) => {
  // ---- Graphql query ---
  const { data: queryData, loading, error, refetch, fetchMore } = useQuery(
    GET_ALL_FACEBOOK_FEEDS,
  )

  if (loading)
    return (
      <BoxWrapper>
        <InLineLoader />
      </BoxWrapper>
    )
  if (error) return <p>Query Error! {error.message}</p>

  const feedResults = queryData.findManyFacebookFeedResult.map((item) => (
    <BoxItem
      key={item.id}
      id={item.id}
      date={item.updatedAt}
      name={item.name}
      url={item.url}
      onGenerate={regenerateFunction}
      loading={mutationLoading}
    />
  ))

  return (
    <BoxWrapper>
      <BoxTitle> Existing Facebook Feeds: </BoxTitle>
      {queryData.findManyFacebookFeedResult.length > 0 ? (
        feedResults
      ) : (
        <p>There are no generated feeds.</p>
      )}
    </BoxWrapper>
  )
}

const BoxItem = ({ id, date, name, url, onGenerate, loading }) => {
  // ---------
  const [notification, setNotification] = useState(false)
  const { enqueue } = useSnackbar()
  const confirm = useConfirmation()

  const [
    deleteOneFacebookFeed,
    {
      data: mutationData,
      called,
      loading: mutationLoading,
      error: mutationError,
    },
  ] = useMutation(DELETE_ONE_FACEBOOK_FEED, {
    onError: (e) => {
      // For mutation errors...
      console.log('Mutation error!', e)
      console.log('Extracted error!', e.graphQLErrors)
      enqueue(
        {
          message: 'Error!',
          startEnhancer: ({ size }) => <Check size={size} />,
          overrides: {
            Message: {
              style: ({ $theme }) => ({
                color: $theme.colors.red400,
              }),
            },
            StartEnhancerContainer: {
              style: ({ $theme }) => ({
                color: $theme.colors.red400,
              }),
            },
          },
        },
        DURATION.short,
      )
    },
    onCompleted: (e) => {
      // Handle the success case.
      enqueue(
        {
          message: 'Feed Deleted!',
          startEnhancer: ({ size }) => <Check size={size} />,
          overrides: {
            Message: {
              style: ({ $theme }) => ({
                color: $theme.colors.lightGreen,
              }),
            },
            StartEnhancerContainer: {
              style: ({ $theme }) => ({
                color: $theme.colors.lightGreen,
              }),
            },
          },
        },
        DURATION.short,
      )
    },
    refetchQueries: ['GET_ALL_FACEBOOK_FEEDS'],
  })

  const onDelete = (e) => {
    e.preventDefault()
    // console.log("Trying to delete facebook feed id: ", id);
    confirm({
      variant: 'danger',
      title: `Are you sure?`,
      description:
        'Deleting a feed might create Facebook import errors if this feed is already used.',
    }).then(() => {
      deleteOneFacebookFeed({ variables: { id: id } })
    })
  }

  const copyText = (e) => {
    navigator.clipboard.writeText(url)
    setNotification(true)
    setTimeout(() => {
      setNotification(false)
    }, 1000)
  }

  return (
    <Row>
      <Col lg={1}>
        <OrderDateComponent dateTime={date} />
      </Col>
      <Col lg={2}>{name}</Col>
      <Col lg={7}>
        <Input
          onFocus={(e) => copyText(e)}
          value={url}
          endEnhancer={
            <AiOutlineCopy onClick={(e) => copyText(e)} size="18px" />
          }
        />
        <NotificationWrapper>
          <NotificationBody>
            {notification && (
              <Notification
                autoHideDuration={700}
                overrides={{
                  Body: { style: { width: '80px' } },
                }}
              >
                {() => 'Copied!'}
              </Notification>
            )}
          </NotificationBody>
        </NotificationWrapper>
      </Col>
      <Col lg={1}>
        <Button
          onClick={(e) => onGenerate(name)}
          disabled={loading}
          overrides={{
            BaseButton: {
              style: ({ $theme }) => ({
                width: '100%',
                borderTopLeftRadius: '3px',
                borderTopRightRadius: '3px',
                borderBottomRightRadius: '3px',
                borderBottomLeftRadius: '3px',
              }),
            },
          }}
        >
          Regenerate
        </Button>
      </Col>
      <Col lg={1}>
        <Button
          kind={KIND.secondary}
          onClick={onDelete}
          disabled={loading}
          overrides={{
            BaseButton: {
              style: ({ $theme }) => ({
                marginLeft: '5px',
                width: '100%',
                background: $theme.colors.red100,
                color: $theme.colors.red400,
                borderTopLeftRadius: '3px',
                borderTopRightRadius: '3px',
                borderBottomRightRadius: '3px',
                borderBottomLeftRadius: '3px',
              }),
            },
          }}
        >
          DELETE
        </Button>
      </Col>
    </Row>
  )
}
