import React, { useCallback, useEffect, useState } from "react";
// import dayjs from "dayjs";
import { withStyle, createThemedUseStyletron } from "baseui";
import { Grid, Row as Rows, Col as Column } from "components/FlexBox/FlexBox";
import { useDrawerDispatch } from "context/DrawerContext";
import Button from "components/Button/Button";
import { Plus } from "assets/icons/PlusMinus";
import { Header, Heading } from "components/Wrapper.style";
import { gql, useMutation, useSubscription } from "@apollo/client";
import { DURATION, useSnackbar } from "baseui/snackbar";
import { Check } from "baseui/icon";
import ProgressBar from "components/ProgressBar/ProgressBar";
import LanguageSwitcher from "components/LanguageSwitcher/LanguageSwitcher";
import LanguageSwitcherProfile from "components/LanguageSwitcher/LanguageSwitcherProfile";
import Checkbox, { LABEL_PLACEMENT } from "components/CheckBox/CheckBox";
import NotificationsTable from "components/NotificationsTable/NotificationsTable";
import { useConfirmation } from "context/ConfirmationServiceContext";

export const RUN_IMAGE_RESIZE_SCRIPT = gql`
  mutation RUN_IMAGE_RESIZE_SCRIPT {
    runImageResizeScript
  }
`;

export const IMAGE_RESIZE_SCRIPT_SUBSCRIPTION = gql`
  subscription IMAGE_RESIZE_SCRIPT_SUBSCRIPTION {
    imageResizeScriptSubscription {
      image
      primary
      secondary
    }
  }
`;

type CustomThemeT = { red400: string; textNormal: string; colors: any };
const themedUseStyletron = createThemedUseStyletron<CustomThemeT>();

const Col = withStyle(Column, () => ({
  "@media only screen and (max-width: 767px)": {
    marginBottom: "20px",
    ":last-child": {
      marginBottom: 0,
    },
  },
}));
const Row = withStyle(Rows, () => ({
  "@media only screen and (min-width: 768px)": {
    alignItems: "center",
  },
}));

export default function ImageResizeScript() {
  // --------
  const [notifications, setNotifications] = useState([]);

  const [scanningPage, setScanningPage] = useState(0);
  const { enqueue } = useSnackbar();
  const confirm = useConfirmation();

  // ==== NOTIFICATIONS SUBSCRIPTION =====
  // const { data: subscriptionData, loading: subscriptionLoading } =
  //   useSubscription(IMAGE_RESIZE_SCRIPT_SUBSCRIPTION);

  // useEffect(() => {
  //   // Update incoming data of subscriptions
  //   if (subscriptionData?.imageResizeScriptSubscription) {
  //     // We limit the length of Notifications array, to avoid browser lags...
  //     setNotifications((oldArray) => {
  //       if (oldArray.length > 100) {
  //         oldArray.length = 100;
  //       }
  //       return [subscriptionData.imageResizeScriptSubscription, ...oldArray];
  //     });
  //   }
  //   // console.log("Subscription data ->", notifications);
  // }, [subscriptionData]);

  //* ==== Mutation to edita data in database
  const [
    runImageResizeScript,
    {
      data: mutationData,
      called,
      loading: mutationLoading,
      error: mutationError,
    },
  ] = useMutation(RUN_IMAGE_RESIZE_SCRIPT, {
    onError: (e) => {
      // For mutation errors...
      console.log("Mutation error!", e);
      console.log("Extracted error!", e.graphQLErrors);
      enqueue(
        {
          message: "Error!",
          startEnhancer: ({ size }) => <Check size={size} />,
          overrides: {
            Message: {
              style: ({ $theme }) => ({
                color: $theme.colors.red400,
              }),
            },
            StartEnhancerContainer: {
              style: ({ $theme }) => ({
                color: $theme.colors.red400,
              }),
            },
          },
        },
        DURATION.short
      );
    },
    onCompleted: (e) => {
      // Handle the success case.
      enqueue(
        {
          message: "Images Resize successfully!",
          startEnhancer: ({ size }) => <Check size={size} />,
          overrides: {
            Message: {
              style: ({ $theme }) => ({
                color: $theme.colors.lightGreen,
              }),
            },
            StartEnhancerContainer: {
              style: ({ $theme }) => ({
                color: $theme.colors.lightGreen,
              }),
            },
          },
        },
        DURATION.short
      );
    },
    // Not sure if needed, after we changed - fetchPolicy: "cache-and-network", on all queries
    // refetchQueries: ["GET_STYLES", "GET_STYLE"],
  });

  const handleScriptStart = () => {
    console.log("Starting script...");

    confirm({
      variant: "danger",
      // catchOnCancel: true,
      title: "Are you sure?",
      description: `Running this script will overwrite existing resizes!`,
    }).then(() => {
      runImageResizeScript();
    });
  };

  //   if (mutationLoading) return <InLineLoader />;
  if (mutationError) return <p>Query Error! {mutationError.message}</p>;

  return (
    <Grid fluid={true}>
      <Row>
        <Col md={12}>
          <Header
            style={{
              marginBottom: 30,
              boxShadow: "0 0 5px rgba(0, 0 ,0, 0.05)",
            }}
          >
            <Col md={6}>
              <Heading>Image Resize Script</Heading>
            </Col>

            <Col md={6}>
              <Button
                onClick={handleScriptStart}
                isLoading={mutationLoading}
                startEnhancer={() => <Plus />}
                overrides={{
                  BaseButton: {
                    style: ({ $theme, $size, $shape }) => {
                      return {
                        width: "100%",
                        borderTopLeftRadius: "3px",
                        borderTopRightRadius: "3px",
                        borderBottomLeftRadius: "3px",
                        borderBottomRightRadius: "3px",
                      };
                    },
                  },
                }}
              >
                Start Script
              </Button>
            </Col>

            {mutationLoading && <ProgressBar infinite={true} value={100} />}
          </Header>

          <NotificationsTable data={notifications} />
        </Col>
      </Row>
    </Grid>
  );
}
