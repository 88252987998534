//* REACTIVE VARIABLES for storing local state outside of the Apollo Client cache
import { makeVar } from '@apollo/client'

// Types
import { Value } from 'baseui/select'
import { AdnvancedProductSkeleton } from './types'

//HOLDS THE STATE (OPEN/CLOSED) FOR THE CART DRAWER COMPONENT
// export const SiteLanguageVar2 = makeVar<Value>([{ id: "lt" }]);
// export const SiteLanguageVar = makeVar('lt')
export const SiteLanguageVar = makeVar('')
export const StoreWebsiteAddressVar = makeVar(null)

export const SiteSettingsVar = makeVar(null)

// Selected languages in settings ======
export const EnabledLanguagesInSettingsVar = makeVar([])

// Multiple Catgeory select Reactive Vars =============
export const CategoriesMultipleSelectValuesVar = makeVar([])
export const CategoriesMultipleSelectPrimaryCategoryVar = makeVar(null)

// === IN Products page we set the default dispatch information
export const DefaultDispatchInformationVar = makeVar(null)

// ==== We need a var to store all selected ProductContainers
export const SelectedProductContainersVar = makeVar([])

// ==== We need a var to store all selected b2bCustomers
export const SelectedB2BCustomersVar = makeVar([])
export const SelectedFabricCategoriesVar = makeVar([])
export const SelectedFabricPalettesVar = makeVar([])

export const SelectedProductContainersForStyleFormVar = makeVar([])

export const ImagesCurrentlyUploadingVar = makeVar(false)

export const FabricsCurrentlyUploadingVar = makeVar(false)

export const SearchWhereStatementsVar = makeVar(undefined)

export const LoginErrorVar = makeVar(false)
export const LoginSuccessVar = makeVar(false)

export const PriceListVar = makeVar(null)

export const ShowInactiveComponentsVar = makeVar(true)

// ====== VARS FOR ADVANCED PRODUCT INPUT DATA GATHERING (we use multiple input windows) ======
export const AdvancedProductSkeletonVar = makeVar({
  advanced_product_type: null,
  manufacturer: null,
  sofa_shapes: [],
} as AdnvancedProductSkeleton)

export const AdvancedProductFormValuesVar = makeVar(null)

// ========= CART VARS =======
export const CartDrawerOpenVar = makeVar(false)
export const LeftDrawerOpenVar = makeVar(false) // For product forms etc...
// export const ToggleCartVar = makeVar(null)
export const CartProductsVar = makeVar([])

//=== SOFA CONFIGURATOR ====
export const sofaScaleVar = makeVar(1)
export const selectedSofaCombinationsVar = makeVar([])
export const selectedAdvancedProductFabricVar = makeVar({
  fabricGroupObject: null,
  fabricObject: null,
  combinationFabrics: null,
})
export const selectedAdvancedProductFabricCombinationVar = makeVar({
  fabricCombination: null,
})

export const componentsDrawerStateVar = makeVar({
  open: false,
  data: null,
  componentGroup: null,
})

export const fabricsDrawerStateVar = makeVar({
  open: false,
  data: null,
  option: null,
})

export const fabricsCombinationDrawerStateVar = makeVar({
  open: false,
  data: null,
})

export const advancedProductTotalPriceVar = makeVar(null)

// ================================= ADDITIONAL COMPONENTS ============================
// This is an array, and we push all selected additional components to it
export const selectedAdditionalComponentsVar = makeVar([])
export const advancedProductVarsResetedVar = makeVar(null)
// To initiate a reset on another product page
export const resetAdvancedProductVars = () => {
  // -- Currently this breaks sofa shape slider (however on each load the first fabric is reselected, so should be no bugs)
  selectedAdvancedProductFabricVar({
    fabricGroupObject: null,
    fabricObject: null,
    combinationFabrics: null,
  })

  selectedSofaCombinationsVar([])
  sofaScaleVar(1)
  advancedProductTotalPriceVar(null)
  selectedAdditionalComponentsVar([])
  advancedProductVarsResetedVar(Date.now())
  selectedSofaCombinationsForEditWindowVar([]) // TURN ON LATER
  advancedProductVarsUpdatedVar(false)
  
  console.log('Resetting VARs...')
}

// This is used to detect State change
export const advancedProductVarsUpdatedVar = makeVar(false)
//--
export const selectedSofaCombinationsForEditWindowVar = makeVar([])


//==== PDF DATA CRUNCH
export const PDFDataCrunchVar = makeVar([])

//* ======= EXAMPLE FOR REACTIVE VARS THAT HAVE TO USE A TYPE DEFINITION =========
// import { VisibilityFilter, VisibilityFilters } from "./models/VisibilityFilter";
// const todosInitialValue: Todos = [
//     {
//         id: 0,
//         completed: false,
//         text: "Use Apollo Client 3"
//     }
// ];
// export const todosVar: ReactiveVar<Todos> = makeVar<Todos>(
//     todosInitialValue
// );
// export const visibilityFilterVar = makeVar<VisibilityFilter>(
//     VisibilityFilters.SHOW_ALL
// );
