import React, { useCallback, useEffect, useState } from 'react'
import { Input as BaseInput, SIZE } from 'baseui/input'
import { gql, useMutation, useQuery, useReactiveVar } from '@apollo/client'
import ProgressBar from 'components/ProgressBar/ProgressBar'
import DrawerSelect from 'components/Select/DrawerSelect'
import { SiteLanguageVar } from 'lib/reactiveVars'
import { extractProfileByLanguage } from 'utils/languages'
import { useDrawerDispatch } from 'context/DrawerContext'
import Button from 'components/Button/Button'
import { RadioGroup, Radio, ALIGN } from 'baseui/radio'
import { ListItem, ListItemLabel } from 'baseui/list'
import { Col, Row } from 'components/FlexBox/FlexBox'
import { FieldDetails } from 'containers/DrawerItems/DrawerItems.style'
import { DURATION, useSnackbar } from 'baseui/snackbar'
import { Check } from 'baseui/icon'
import { useConfirmation } from 'context/ConfirmationServiceContext'
import { Label4 } from 'baseui/typography'
import { TYPE } from 'baseui/select'
import { priceFormatter } from 'utils/priceUtils'

export const GET_SHIPPING_METHODS_FOR_SELECT = gql`
  query GET_SHIPPING_METHODS_FOR_SELECT {
    findManyShippingMethod{
      id
      is_enabled
      dynamic_pricing
      shipping_provider
      price_for_box

      shipping_method_profiles {
        id
        title
        language
      }
    }
  }
`

const ShippingMethodSelect = ({ onChange, value, placeholder = null }) => {
  // Value is either null Or id of parent category.
  const [optionValues, setOptionValues] = useState(null)
  // === Site language
  const siteLanguage = useReactiveVar(SiteLanguageVar)
  // === GRAPHQL Query to get Languages

  const { loading, error, data } = useQuery(GET_SHIPPING_METHODS_FOR_SELECT, {
    // -- Turning off, as referch queries are a lot more efficient
    // fetchPolicy: "cache-and-network",
  })

  useEffect(() => {
    if (value && data && shippingMethodOptions) {
      let selectedValues = [] as any
      // console.log("Style value ->", value);
      for (const item of value) {
        const selectedValue = shippingMethodOptions.filter(
          (option) => option.id === item.id,
        )
        selectedValues = [...selectedValues, ...selectedValue]
      }
      setOptionValues(selectedValues)
    }
  }, [value, data])

  if (loading) return <ProgressBar infinite={true} value={100} />
  if (error) return <p>Query Error! {error.message}</p>

  const shippingMethodOptions = data.findManyShippingMethod.map((shippingMethod) => {
    const languageProfile = extractProfileByLanguage(
      siteLanguage,
      shippingMethod.shipping_method_profiles,
    )

    const priceFormated = priceFormatter(shippingMethod.price_for_box)

    const optionObject = {
      id: shippingMethod.id,
      name: languageProfile
        ? `${languageProfile.title} - (${priceFormated}) `
        : `ID: ${shippingMethod.id
        } - (Not translated) - ${siteLanguage.toUpperCase()}`,
    }
    return optionObject
  })

  const handleChange = (value) => {
    // Pass the value up (onChange)
    const selectedValuesList = value.map((item) => {
      return {
        id: item.id,
      }
    })

    onChange(selectedValuesList)
  }

  return (
    <DrawerSelect
      options={shippingMethodOptions}
      labelKey="name"
      valueKey="id"
      placeholder={placeholder ?? 'Select...'}
      onChange={(e) => handleChange(e.value)}
      value={optionValues}
      searchable={true}
      clearable={true}
      // ==== We revert to single style and collection (due to product page display)
      type={TYPE.search}
      multi
    />
  )
}

export default ShippingMethodSelect
