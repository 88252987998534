// TODO Transfer to utility functions folder
export const removeKey = (obj, key) =>
  obj !== Object(obj)
    ? obj
    : Array.isArray(obj)
    ? obj.map((item) => removeKey(item, key))
    : Object.keys(obj)
        .filter((k) => k !== key)
        .reduce(
          (acc, x) => Object.assign(acc, { [x]: removeKey(obj[x], key) }),
          {}
        );

export function arraysEqual(a, b) {
  if (a === b) return true;
  if (a == null || b == null) return false;
  if (a.length !== b.length) return false;

  // If you don't care about the order of the elements inside
  // the array, you should sort both arrays here.
  // Please note that calling sort on an array will modify that array.
  // you might want to clone your array first.

  for (var i = 0; i < a.length; ++i) {
    if (a[i] !== b[i]) return false;
  }
  return true;
}

export const extractIDsToList = (list) => {
  let newList = [] as any;
  for (const item of list) {
    if (item.id) {
      newList.push(item.id);
    } else {
      alert("Selected items have no ids...");
    }
  }
  return newList;
};

// ORDER NUMBER
export const orderNumberFormatter = (orderNumber) => {
  const size = 5;
  let num = orderNumber.toString();
  while (num.length < size) num = "0" + num;
  return num;

  return num;
};
