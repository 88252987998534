import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import { Client as Styletron } from 'styletron-engine-atomic'
import { Provider as StyletronProvider } from 'styletron-react'
import { BaseProvider } from 'baseui'
import {
  ApolloProvider,
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  HttpLink,
  split,
} from '@apollo/client'
import { theme } from './theme'
import Routes from './routes'
import * as serviceWorker from './serviceWorker'
import './theme/global.css'
import { SnackbarProvider } from 'baseui/snackbar'
import { createUploadLink } from 'apollo-upload-client'
import { getMainDefinition } from '@apollo/client/utilities'
import { setContext } from '@apollo/client/link/context'
import { ThemeProvider } from 'styled-components'
import { defaultTheme } from 'theme/styledComponentsTheme'
import { ThemeProvider as MuiThemeProvider, Theme } from '@mui/material/styles'
import muiTheme from 'theme/muiTheme'
import { GraphQLWsLink } from '@apollo/client/link/subscriptions'
import { createClient } from 'graphql-ws'

const GRAPHQL_ENDPOINT = process.env.REACT_APP_API_URL
const GRAPHQL_ENDPOINT_WS = process.env.REACT_APP_API_URL_WS

const wsLink = new GraphQLWsLink(
  createClient({
    url: GRAPHQL_ENDPOINT_WS,
    connectionParams: {
      //authToken: user.authToken,
      reconnect: true,
    },
  }),
)

const httpLinkOrigin = new createUploadLink({
  uri: GRAPHQL_ENDPOINT,
})

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem('furnisystems_token')
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  }
})

const httpLink = authLink.concat(httpLinkOrigin)

// const httpLink = new HttpLink({
//   uri: GRAPHQL_ENDPOINT,
// });

// The split function takes three parameters:
//
// * A function that's called for each operation to execute
// * The Link to use for an operation if the function returns a "truthy" value
// * The Link to use for an operation if the function returns a "falsy" value

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query)
    // console.log(definition)
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    )
  },
  wsLink,
  httpLink,
)

export const client = new ApolloClient({
  link: splitLink,
  // uri: GRAPHQL_ENDPOINT,
  // link: createUploadLink({
  //   url: GRAPHQL_ENDPOINT,
  // }), // We do need this to handle upload streams.
  cache: new InMemoryCache({
    typePolicies: {
      // TODO When updating queries with __Count this comes deprecated
      Manufacturer: {
        fields: {
          products: {
            keyArgs: [],
            merge(existing = [], incoming) {
              return [...incoming]
            },
          },
        },
      },
      Style: {
        fields: {
          products: {
            keyArgs: [],
            merge(existing = [], incoming) {
              return [...incoming]
            },
          },
        },
      },
      Collection: {
        fields: {
          products: {
            keyArgs: [],
            merge(existing = [], incoming) {
              return [...incoming]
            },
          },
        },
      },
      Category: {
        fields: {
          products: {
            keyArgs: [],
            merge(existing = [], incoming) {
              return [...incoming]
            },
          },
        },
      },
      // TODO When updating queries with __Count this comes deprecated (above)
      ProductContainer: {
        fields: {
          styles: {
            keyArgs: [],
            merge(existing = [], incoming) {
              return [...incoming]
            },
          },
          collections: {
            keyArgs: [],
            merge(existing = [], incoming) {
              return [...incoming]
            },
          },
          categories: {
            keyArgs: [],
            merge(existing = [], incoming) {
              return [...incoming]
            },
          },
        },
      },
      // TODO Not sure about(above)

      Query: {
        fields: {
          findManyProductContainer: {
            // merge: true,
            // keyArgs: false,
            keyArgs: ['where', 'orderBy'],
            merge(existing = [], incoming, { readField, mergeObjects, args }) {
              // console.log("existing ->>", existing);
              // console.log("incoming ->>", incoming);
              // console.log("We have args --->", args);
              // console.log("incoming.length ->", incoming.length);

              const currentProductsLength = existing.length + incoming.length

              const merged: any[] = existing ? existing.slice(0) : []
              //* this creates an object Record<K, T> where K is the type of all the keys and T is the type of all the values
              const productToIndex: Record<number, number> = Object.create(null)

              //* ================= If "existing" is defined we loop through it and for each product we create a key value pair where key is the productID and the value is the index of that id in the "existing" array. =================
              if (existing) {
                existing.forEach((product, index) => {
                  productToIndex[readField<number>('id', product)] = index
                })
              }

              //* ============ We always loop through the "incoming" array, we get the id of each product and his index from the "productToIndex" object. We have to check if it exists and if the type of the index is number.
              incoming.forEach((product) => {
                const productID = readField<number>('id', product)
                const index = productToIndex[productID]
                if (typeof index === 'number') {
                  // Merge the new product data with the existing product data. The mergeObjects helper function will check if the __ref is the same in the two objects and if it is, it will safely merge the two.
                  merged[index] = mergeObjects(merged[index], product)
                } else {
                  // It's the first time we see this product so we add it the productToIndex object, assigning to the key the product ID and to the value the length of the merged array
                  productToIndex[productID] = merged.length
                  // merged.push(product);
                  merged.push(product) // Add new items to the geining
                }
              })

              // There were errors with merging, becasue of fetchMore function loading state in Products.tsx
              // console.log("currentProductsLength :>> ", currentProductsLength);
              // console.log("merged.length :>> ", merged.length);

              // Handling Refetch when new peroduct is created...
              if (merged.length !== currentProductsLength) {
                // We added a product and refetched...
                // console.log("New product case...");
                // Move that product from back to front
                let last_element = merged[merged.length - 1]
                merged.unshift(last_element) // Add last element to front
                merged.pop() // Remove last element (as now its a duplicate)
                // return incoming;
              }
              return merged

              // -- Merge arrays (previous version)
              // return [...existing, ...incoming]
            },
          },

          // TODO Should be fixed with normalized cache (check if still persists after updating packages...)
          // This happens after deleting a style or a collection (A warning pops up)
          // ---- When updating queries...
          styles: {
            keyArgs: ['where', 'orderBy', 'first', 'last', 'after', 'before'],
            merge(existing = [], incoming) {
              return [...incoming]
            },
          },
          collections: {
            keyArgs: ['where', 'orderBy', 'first', 'last', 'after', 'before'],
            merge(existing = [], incoming) {
              return [...incoming]
            },
          },
          manufacturers: {
            keyArgs: ['where', 'orderBy', 'first', 'last', 'after', 'before'],
            merge(existing = [], incoming) {
              return [...incoming]
            },
          },
          categories: {
            keyArgs: ['where', 'orderBy', 'first', 'last', 'after', 'before'],
            merge(existing = [], incoming) {
              return [...incoming]
            },
          },
          colors: {
            keyArgs: ['where', 'orderBy', 'first', 'last', 'after', 'before'],
            merge(existing = [], incoming) {
              return [...incoming]
            },
          },
          blogPosts: {
            keyArgs: ['where', 'orderBy', 'first', 'last', 'after', 'before'],
            merge(existing = [], incoming) {
              return [...incoming]
            },
          },
        },
      },
    },
  }),
})

function App() {
  const engine = new Styletron()

  return (
    <ApolloProvider client={client as any}>
      <StyletronProvider value={engine}>
        <MuiThemeProvider theme={muiTheme}>
          <ThemeProvider theme={defaultTheme}>
            <BaseProvider theme={theme}>
              <BrowserRouter>
                <SnackbarProvider
                  overrides={{
                    PlacementContainer: {
                      style: ({ $theme }) => ({
                        zIndex: 1000,
                      }),
                    },
                  }}
                  placement="bottomLeft"
                >
                  <Routes />
                </SnackbarProvider>
              </BrowserRouter>
            </BaseProvider>
          </ThemeProvider>
        </MuiThemeProvider>
      </StyletronProvider>
    </ApolloProvider>
  )
}

ReactDOM.render(<App />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
