import React, { useCallback, useEffect, useState } from 'react'
import { styled, withStyle, createThemedUseStyletron } from 'baseui'
import { Grid, Row, Col } from 'components/FlexBox/FlexBox'
import Select from 'components/Select/Select'
import Input from 'components/Input/Input'
import {
  useQuery,
  gql,
  useMutation,
  useLazyQuery,
  useReactiveVar,
} from '@apollo/client'
import { Wrapper, Header, Heading } from 'components/Wrapper.style'
import ThemeButton from 'components/Button/Button'
// import {Button} from 'baseui/button';
import { Button, KIND, SIZE, SHAPE } from 'baseui/button'
import NoResult from 'components/NoResult/NoResult'
import { useDrawerDispatch } from 'context/DrawerContext'
import DataTable from 'react-data-table-component'
import { Check, Delete, Plus, Spinner } from 'baseui/icon'
import { FiEdit, FiArrowRight, FiFrown, FiEyeOff } from 'react-icons/fi'
import { RiContactsBookLine, RiDeleteBin2Line } from 'react-icons/ri'
import useDebounce from '../../utils/hooks/debounce'
import { useConfirmation } from 'context/ConfirmationServiceContext'
import { InLineLoader } from 'components/InlineLoader/InlineLoader'
import { SiteLanguageVar } from 'lib/reactiveVars'
import { largeWidth, midWidth, sm, smallWidth } from 'settings/constants'
import { extractProfileByLanguage } from 'utils/languages'
import { DURATION, useSnackbar } from 'baseui/snackbar'
import { LogoContainer } from './Blog.style'
import usePaging from 'utils/hooks/usePaging'

// TODO Update this query to when prisma rollouts _count functionality...
// This will serch through all color profiles (no matter the language)
export const GET_BLOG_POSTS = gql`
  query GET_BLOG_POSTS(
    $searchTerm: String
    $language: EnumLanguageFilter
    $take: Int
    $skip: Int
    $orderBy: [BlogPostOrderByWithRelationInput!]
  ) {
    findManyBlogPostCount(
      where: {
        blog_post_profiles: {
          some: {
            title: { mode: insensitive, contains: $searchTerm }
            language: $language
          }
        }
      }
    )
    findManyBlogPost(
      orderBy: $orderBy
      where: {
        blog_post_profiles: {
          some: {
            title: { mode: insensitive, contains: $searchTerm }
            language: $language
          }
        }
      }
      take: $take 
      skip: $skip
    ) {
      id
      createdAt
      updatedAt
      active
      author {
        id
        name
      }
      featured_image {
        id
        src
      }
      blog_post_profiles {
        id
        title
        language
        meta_information {
          id
          permalink
        }
      }
      #  === Product selecet
      related_products {
        id
      }
    }
  }
`

const DELETE_SINGLE_BLOG_POST = gql`
  mutation DELETE_SINGLE_BLOG_POST($id: Int!) {
    deleteSingleBlogPost(id: $id) {
      id
    }
  }
`

type CustomThemeT = { red400: string; textNormal: string; colors: any }
const themedUseStyletron = createThemedUseStyletron<CustomThemeT>()

export default function BlogPosts() {
  const [searchTerm, setSearchTerm] = useState('')
  // Controlling DRAWER (The edit form) dispatch from here...
  const dispatch = useDrawerDispatch()

  // ======= FORM Drawer CONTROLS ==========
  // console.log("New search term ->", searchTerm);
  const openDrawerNewBlogPost = useCallback(
    () => dispatch({ type: 'OPEN_DRAWER', drawerComponent: 'BLOG_POST_FORM' }),
    [dispatch],
  )

  const openDrawerUpdateColor = useCallback(
    (postID) => {
      dispatch({
        type: 'OPEN_DRAWER',
        drawerComponent: 'BLOG_POST_UPDATE_FORM',
        data: {
          postID,
        },
      })
    },
    [dispatch],
  )
  // ======= FORM Drawer CONTROLS (above) ==========

  return (
    <Grid fluid={true}>
      <Row>
        <Col md={12}>
          <Header
            style={{
              marginBottom: 5,
              boxShadow: '0 0 8px rgba(0, 0 ,0, 0.1)',
            }}
          >
            <Col md={3} xs={12}>
              <Heading>Blog</Heading>
            </Col>

            <Col md={9} xs={12}>
              <Row>
                <Col md={8} xs={12}>
                  <SearchInput setSearchTerm={setSearchTerm} />
                </Col>

                <Col md={4} xs={12}>
                  <ThemeButton
                    style={{ width: '100%' }}
                    startEnhancer={() => <Plus size={20} />}
                    onClick={openDrawerNewBlogPost}
                  >
                    New Blog Post
                  </ThemeButton>
                </Col>
              </Row>
            </Col>
          </Header>

          <Wrapper
            style={{ boxShadow: '0 0 5px rgba(0, 0 , 0, 0.05)', marginTop: 20 }}
          >
            <BlogPostsTable
              searchTerm={searchTerm}
              updateColor={openDrawerUpdateColor}
              openDrawerNewForm={openDrawerNewBlogPost}
            />
          </Wrapper>
        </Col>
      </Row>
    </Grid>
  )
}

const BlogPostsTable = ({ searchTerm, updateColor, openDrawerNewForm }) => {
  const [useCss, theme] = themedUseStyletron()
  const confirm = useConfirmation()
  const { enqueue } = useSnackbar()
  // We want to pass the language, ONLY when we have a search term.
  const siteLanguage = useReactiveVar(SiteLanguageVar)
  let language = undefined
  if (searchTerm) {
    language = { equals: siteLanguage }
  }

  const [selectedRows, setSelectedRows] = useState(null)
  const [selectedCount, setSelectedCount] = useState(0)
  const [resetSelectedRows, setResetSelectedRows] = useState(false)
  const [orderBy, setOrderBy] = useState(undefined) // (SORTING)
  const { pageNumber, paginationPerPage, onChangePage, onChangeRowsPerPage, resetPaginationToggle, getFilters } = usePaging(1, 50);
  const [data, setData] = useState(undefined)

  // console.log("Rendering");

  // TODO Modify for blog posts...
  //* ==== Mutation to edita data in database
  const [
    deleteSingleBlogPost,
    {
      data: mutationData,
      called,
      loading: mutationLoading,
      error: mutationError,
    },
  ] = useMutation(DELETE_SINGLE_BLOG_POST, {
    onError: (e) => {
      // For mutation errors...
      console.log('Mutation error!', e)
      console.log('Extracted error!', e.graphQLErrors)
      enqueue(
        {
          message: 'Error!',
          startEnhancer: ({ size }) => <Check size={size} />,
          overrides: {
            Message: {
              style: ({ $theme }) => ({
                color: $theme.colors.red400,
              }),
            },
            StartEnhancerContainer: {
              style: ({ $theme }) => ({
                color: $theme.colors.red400,
              }),
            },
          },
        },
        DURATION.short,
      )
    },
    onCompleted: (e) => {
      // Handle the success case.
      enqueue(
        {
          message: 'Blog Post Deleted!',
          startEnhancer: ({ size }) => <Check size={size} />,
          overrides: {
            Message: {
              style: ({ $theme }) => ({
                color: $theme.colors.lightGreen,
              }),
            },
            StartEnhancerContainer: {
              style: ({ $theme }) => ({
                color: $theme.colors.lightGreen,
              }),
            },
          },
        },
        DURATION.short,
      )
      setResetSelectedRows(!resetSelectedRows)
    },

    refetchQueries: ['GET_BLOG_POSTS'],
  })

  // === GRAPHQL Query to get Colors
  const [
    fetchBlogPostsLazy,
    { loading, error, data: queryData },
  ] = useLazyQuery(GET_BLOG_POSTS, {
    variables: {
      searchTerm,
      language,
      ...getFilters(),
      orderBy,
    },
    // -- Turning off, as referch queries are a lot more efficient
    // fetchPolicy: "cache-and-network",
  })

  useEffect(() => {
    setOrderBy({ createdAt: 'desc' })
  }, [])

  // We need this useEffec to control refetching of information, when state changes.
  // We fetch only when information related to the query changes.
  useEffect(() => {
    // console.log("Fetching...");
    fetchBlogPostsLazy()
  }, [
    searchTerm,
    siteLanguage,
    paginationPerPage,
    pageNumber,
    fetchBlogPostsLazy,
  ])

  // This effect controls the re-rendering process of the container.
  // Initially we show no table, and we want it not to rerender
  // Because if it does, it loses its inner state values and forgets which pagination page is active
  useEffect(() => {
    // console.log("queryData Changed ->", queryData);
    if (queryData) {
      setData(queryData)
    }
  }, [queryData])

  // This has to be above all else, because useConfirmation hook uses context.
  // This registers to context and fires a rerender, therefore, we do not want to render it conditionaly.
  // ======== DATA MIGRATION FUNCTIONS ===============================================================
  const deleteOneBlogPost = (id) => {
    // Delete this id.
    deleteSingleBlogPost({
      variables: { id },
    })
  }

  const deleteManyColors = async () => {
    // selectedRows --> Delete all in this list
    if (selectedRows) {
      const colorsToDelete = await extractIDsToList(selectedRows)

      console.log('Use Memo')
      confirm({
        variant: 'danger',
        // catchOnCancel: true,
        title: `Are you sure, you want to DELETE (${selectedCount}) blog posts??`,
        description: 'Knock yourself out...',
      }).then(() => {
        // console.log("Deleting many colors -->", colorsToDelete);
        for (const colorId of colorsToDelete) {
          deleteSingleBlogPost({
            variables: { id: colorId },
          })
        }
      })
    } else {
      // Say something...
      alert('Nothing selected..')
    }
  }

  //This is a UTILITY FUNCTION. Not moving out, because might be closely associated with table rows.
  const extractIDsToList = (list) => {
    let newList = [] as any
    for (const item of list) {
      if (item.id) {
        newList.push(item.id)
      } else {
        alert('Selected items have no ids...')
      }
    }
    return newList
  }

  // ======== DATA MIGRATION FUNCTIONS (above) =====================================================================
  // ---------------------------------------------------------------------------------------------------------------
  //* ========== get Apollo data (CONDITIONAL RENDERING - EVERYTHING BELOW IS NOT RENERED UNTIL WE HAVE DATA) ======
  // const { data, error, refetch } = useQuery(GET_ORDERS);
  if (!data) return <InLineLoader />
  // if (loading) return <InLineLoader />;  // DO NOT USE ANYMORE, because it triggers a rerender and breaks pagination
  if (error) {
    return <div>Error! color {error.message}</div>
  }
  //* ========== get Apollo data (above) ========

  // ================ TABLE PAGINATION ========================
  const totalRows = data.findManyBlogPostCount // This variable comes from Graphql query.
  // ================ TABLE PAGINATION (above) ================
  // ================ TABLE SORTING ================
  const handleSort = (column, sortDirection: 'desc' | 'asc') => {
    // Sort stuff.
    // console.log("column ->", column);
    // console.log("sortDirection ->", sortDirection);
    switch (column.selector) {
      case 'id':
        setOrderBy({ id: sortDirection })
        break
      default:
        setOrderBy({ createdAt: 'desc' })
    }
  }
  // ================ TABLE SORTING (above) ================

  const handleAction = (id) => {
    console.log('Handling...', id)
    // Update drawer open is in the parent component
    updateColor(id)
  }

  const handleDelete = (id) => {
    // console.log("Trying to delete color id ->", id);
    // Confirm pops up a dialog asks if user wants to continue?
    confirm({
      variant: 'danger',
      // catchOnCancel: true,
      title: 'Are you sure?',
      description: '... ',
    }).then(() => deleteOneBlogPost(id))
    // .catch(() => console.log("DO NOT continue..."));
  }

  const handleSelectedRowsChange = ({
    allSelected,
    selectedCount,
    selectedRows,
  }) => {
    setSelectedRows(selectedRows)
    setSelectedCount(selectedCount)
  }

  const columns = [
    {
      name: 'ID',
      selector: 'id',
      hide: sm,
      sortable: true,
      width: smallWidth,
    },
    {
      name: 'Is Public',
      selector: 'active',
      sortable: false,
      center: true,
      width: smallWidth,
      cell: (row) => {
        if (row.active) {
          return 'Yes'
        } else {
          return 'no'
        }
      },
    },

    {
      name: 'Image',
      selector: 'featured_image',
      sortable: false,
      center: true,
      width: largeWidth,
      cell: (row) => {
        if (row.featured_image) {
          return (
            <LogoContainer
              style={{
                background: `url(${row.featured_image}) center center/contain no-repeat`,
              }}
            />
          )
        } else {
          return <FiEyeOff size={'20px'} />
        }
      },
    },

    {
      name: `Title - (${siteLanguage.toUpperCase()})`,
      selector: 'title',
      sortable: true,
      grow: 2,
    },

    {
      name: 'Related Products',
      selector: 'number_of_products',
      sortable: true,
      hide: sm,
      width: midWidth,
    },

    {
      name: 'Delete',
      sortable: false,
      button: true,
      cell: (row) => (
        <Button
          kind={KIND.minimal}
          size={SIZE.compact}
          shape={SHAPE.circle}
          onClick={() => handleDelete(row.id)}
        >
          <RiDeleteBin2Line color={'red'} size={'1.3em'} />
        </Button>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      width: smallWidth,
    },
    {
      name: 'Edit',
      sortable: false,
      button: true,
      cell: (row) => (
        <Button
          kind={KIND.minimal}
          size={SIZE.compact}
          shape={SHAPE.circle}
          onClick={() => handleAction(row.id)}
        >
          <FiArrowRight size={'1.5em'} />
        </Button>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      width: smallWidth,
    },
  ]

  const dataModified = data.findManyBlogPost.map((blogPost) => {
    const blogProfile = extractProfileByLanguage(
      siteLanguage,
      blogPost.blog_post_profiles,
    )

    return {
      id: blogPost.id,
      active: blogPost.active,
      featured_image: blogPost.featured_image
        ? blogPost.featured_image.src
        : null,
      title: blogProfile ? blogProfile.title : '(empty)',
      number_of_products: blogPost.related_products.length,
    }
  })

  return (
    <Grid fluid={true}>
      <DataTable
        // title="Colors List"  // Not displayed when noHeader=true
        columns={columns}
        data={dataModified}
        striped={true}
        highlightOnHover={true}
        pointerOnHover={true}
        onRowClicked={(row) => handleAction(row.id)}
        paginationRowsPerPageOptions={[10, 25, 50, 100, 200]}
        subHeader={true}
        noContextMenu={true} // To remove the blue bar showing how many rows you have selected
        noHeader={true}
        subHeaderComponent={
          <SubHeader
            onDelete={deleteManyColors}
            rowsSelected={selectedCount > 0 ? true : false}
            rowsCount={selectedCount}
            loading={loading}
          />
        }
        noDataComponent={
          <NoResult
            message={'You have no blog posts, please create one! :)'}
            buttonText={'+ New Blog Post'}
            onClick={openDrawerNewForm}
          />
        }
        onSelectedRowsChange={handleSelectedRowsChange}
        pagination
        paginationServer
        paginationTotalRows={totalRows}
        paginationPerPage={paginationPerPage}
        sortServer
        onSort={handleSort}
        selectableRows
        onChangeRowsPerPage={onChangeRowsPerPage}
        onChangePage={onChangePage}
        paginationResetDefaultPage={resetPaginationToggle}
        clearSelectedRows={resetSelectedRows}
        disabled={loading}
      />
    </Grid>
  )
}

const SearchInput = ({ setSearchTerm }) => {
  const [searchString, setSearchString] = useState('')
  const debouncedSearchTerm = useDebounce(searchString, 500)

  useEffect(() => {
    if (debouncedSearchTerm) {
      setSearchTerm(debouncedSearchTerm)
    }
  }, [debouncedSearchTerm, setSearchTerm])

  const handleEnter = (e) => {
    // Handling Enter Clicks
    if (e.charCode === 13) {
      setSearchTerm(searchString)
    }
  }

  const handleInputChange = (e) => {
    if (!e.target.value) {
      setSearchString(e.target.value)
      setSearchTerm(e.target.value)
    } else {
      setSearchString(e.target.value)
    }
  }

  return (
    <Input
      value={searchString}
      onChange={handleInputChange}
      placeholder="Search by name"
      clearable
      clearOnEscape
      onKeyPress={handleEnter}
    />
  )
}

const SubHeader = ({ onDelete, rowsSelected, rowsCount, loading }) => {
  // const [searchString, setSearchString] = useState("");
  return (
    <>
      <Row style={{ width: '100%', padding: 0 }}>
        <Col md={12}>
          <Col md={6} style={{ float: 'left' }}>
            <Button
              // onClick={() => alert("click")}
              kind={KIND.secondary}
              size={SIZE.compact}
              disabled
              overrides={{
                BaseButton: {
                  style: ({ $theme }) => ({
                    color: $theme.colors.textNormal,
                    marginLeft: '-40px',
                  }),
                },
              }}
            >
              Selected: {rowsCount}
            </Button>
            {loading ? <Spinner /> : null}
          </Col>
          <Col md={6} style={{ float: 'right' }}>
            <Button
              onClick={onDelete}
              size={SIZE.compact}
              disabled={!rowsSelected}
              overrides={{
                BaseButton: {
                  style: ({ $theme }) => ({
                    backgroundColor: $theme.colors.red400,
                  }),
                },
              }}
            >
              Delete
            </Button>
          </Col>
        </Col>
      </Row>
    </>
  )
}
