import { gql } from "@apollo/client"

export const GET_ACCOUNT_MANAGERS = gql`
  query GET_ACCOUNT_MANAGERS(
    $searchTerm: String
    $take: Int
    $skip: Int
    $orderBy: [CustomerOrderByWithRelationInput!]
    $role: CustomerRole
  ) {
    findManyCustomerCount(
      where: {
        OR: [
          { name: { mode: insensitive, contains: $searchTerm } }
          { surname: { mode: insensitive, contains: $searchTerm } }
          { b2b_company_name: { mode: insensitive, contains: $searchTerm } }
        ]
        is_b2b_user: { equals: true }
        role: { equals: $role }
      }
    )

    findManyCustomer(
      orderBy: $orderBy
      where: {
        OR: [
          { name: { mode: insensitive, contains: $searchTerm } }
          { surname: { mode: insensitive, contains: $searchTerm } }
          { b2b_company_name: { mode: insensitive, contains: $searchTerm } }
        ]
        is_b2b_user: { equals: true }
        role: { equals: $role }
      }
      take: $take
      skip: $skip
    ) {
      id
      createdAt
      name
      surname
      email
      default_phone_number
      language
      buying_as_company

      is_b2b_user
      b2b_company_name
      b2b_customer_discount
      b2b_company_country

      orders {
        id
      }

      # is this a registered user?
      customer_account {
        id
      }
      role
      _count {
        managed_customers
      }
    }
  }
`

export const DELETE_SINGLE_B2B_CUSTOMER = gql`
  mutation DELETE_SINGLE_B2B_CUSTOMER($id: Int!) {
    deleteSingleB2BCustomer(id: $id)
  }
`

export const CORE_B2B_CUSTOMER_DATA = gql`
  fragment CoreB2BCustomerData on Customer {
    id
    createdAt
    updatedAt

    name
    surname
    email
    language

    customer_account {
      id
      password
    }

    selected_products {
      product_containerId
    }
    b2b_only_selected_products
    role
    managed_customers {
      id
      customer {
        id
      }
    }
  }
`

//  TODO ======
export const CREATE_ACCOUNT_MANAGER = gql`
  ${CORE_B2B_CUSTOMER_DATA}
  mutation CREATE_B2B_CUSTOMER(
    $name: String!
    $surname: String
    $email: String!
    $default_phone_number: String
    $is_b2b_user: Boolean!
    $b2b_customer_discount: Int
    $b2b_company_name: String
    $b2b_company_code: String
    $b2b_company_vat_code: String
    $b2b_company_website: String
    $phone_number: String!
    $country: String!
    $city: String!
    $postal_code: String!
    $state_region: String
    $address_1: String!
    $address_2: String
    $password: String!
    $selected_products: [CustomerToProductContainerWhereUniqueInput]!
    $role: CustomerRole
    $b2b_only_selected_products: Boolean!
    $managed_customers: [Int]
  ) {
    createB2Bcustomer(
      name: $name
      surname: $surname
      email: $email
      default_phone_number: $default_phone_number
      is_b2b_user: $is_b2b_user
      b2b_customer_discount: $b2b_customer_discount
      b2b_company_name: $b2b_company_name
      b2b_company_code: $b2b_company_code
      b2b_company_vat_code: $b2b_company_vat_code
      b2b_company_website: $b2b_company_website
      phone_number: $phone_number
      country: $country
      city: $city
      postal_code: $postal_code
      state_region: $state_region
      address_1: $address_1
      address_2: $address_2
      password: $password
      selected_products: $selected_products
      role: $role
      b2b_only_selected_products: $b2b_only_selected_products
      managed_customers: $managed_customers
    ) {
      ...CoreB2BCustomerData
    }
  }
`

export const UPDATE_ACCOUNT_MANAGER = gql`
  ${CORE_B2B_CUSTOMER_DATA}
  mutation UPDATE_ACCOUNT_MANAGER(
    $where: CustomerWhereUniqueInput!
    $name: String!
    $surname: String
    $email: String!
    $default_phone_number: String
    $is_b2b_user: Boolean!
    $b2b_customer_discount: Int
    $b2b_company_name: String
    $b2b_company_code: String
    $b2b_company_vat_code: String
    $b2b_company_website: String
    $phone_number: String!
    $country: String!
    $city: String!
    $postal_code: String!
    $state_region: String
    $address_1: String!
    $address_2: String
    $password: String!
    $selected_products: [CustomerToProductContainerWhereUniqueInput]!
    $b2b_only_selected_products: Boolean!
    $managed_customers: [Int]
  ) {
    updateB2Bcustomer(
      where: $where
      name: $name
      surname: $surname
      email: $email
      default_phone_number: $default_phone_number
      is_b2b_user: $is_b2b_user
      b2b_customer_discount: $b2b_customer_discount
      b2b_company_name: $b2b_company_name
      b2b_company_code: $b2b_company_code
      b2b_company_vat_code: $b2b_company_vat_code
      b2b_company_website: $b2b_company_website
      phone_number: $phone_number
      country: $country
      city: $city
      postal_code: $postal_code
      state_region: $state_region
      address_1: $address_1
      address_2: $address_2
      password: $password
      selected_products: $selected_products
      b2b_only_selected_products: $b2b_only_selected_products
      managed_customers: $managed_customers
    ) {
      ...CoreB2BCustomerData
    }
  }
`

export const GET_ACCOUNT_MANAGER = gql`
  ${CORE_B2B_CUSTOMER_DATA}
  query GET_ACCOUNT_MANAGER($id: Int!) {
    findUniqueCustomer(where: { id: $id }) {
      ...CoreB2BCustomerData
    }
  }
`