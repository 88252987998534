import React from "react";
import { Input as BaseInput, SIZE } from "baseui/input";

const getInputFontStyle = ({ $theme }) => {
  return {
    color: $theme.colors.textDark,
    ...$theme.typography.fontBold16,
  };
};

const Input = React.forwardRef((props: any, ref) => {
  return (
    <BaseInput
      inputRef={ref}
      overrides={{
        Input: {
          style: ({ $theme }) => {
            return {
              ...getInputFontStyle({ $theme }),
            };
          },
        },
      }}
      {...props}
    />
  );
});

export { SIZE };
export default Input;
