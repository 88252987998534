import React, { useEffect, useState } from 'react'

import * as countryFlagIcons from 'country-flag-icons/react/3x2'
import { countries } from 'country-flag-icons'
import countryList from 'country-list'

import DrawerSelect from 'components/Select/DrawerSelect'
import { getCountryName } from 'utils/getCountryName'
import { TYPE } from 'baseui/select'
import { useReactiveVar } from '@apollo/client'
import { SiteLanguageVar } from 'lib/reactiveVars'

const CountryOfOriginSelect = ({ onChange, value, placeholder = null }) => {
  //---
  // Value is either null Or id of parent category.
  const [optionValue, setOptionValue] = useState(null)

  // const siteLanguage = useReactiveVar(SiteLanguageVar) ?? 'en'

  let countryList = {}
  countries.forEach((country) => {
    const code = country
    const name = getCountryName(code, 'en') // Change 'en' to the desired language code
    countryList[code.toLowerCase()] = {
      flag: countryFlagIcons[code],
      name,
      code: code.toUpperCase(),
    }
  })

  let countryOptions = countries.map((countryCode) => ({
    id: countryCode,
    name: countryList[countryCode.toLowerCase()].name,
    flag: countryList[countryCode.toLowerCase()].flag,
    code: countryCode,
  }))

  useEffect(() => {
    if (value) {
      // setOptionValue(value)
      const selectedOption = countryOptions.filter((item) => item.id === value)
      setOptionValue(selectedOption)
    }
  }, [value])

  const handleChange = (value) => {
    // Pass the value up (onChange)
    if (value.option) {
      setOptionValue([value.option])
      onChange(value.option.id)
    } else {
      setOptionValue(null)
      onChange('')
    }
  }

  return (
    <DrawerSelect
      options={countryOptions}
      getOptionLabel={getLabel}
      getValueLabel={getLabel}
      placeholder={optionValue ? '' : placeholder ? placeholder : 'Select...'}
      onChange={handleChange}
      labelKey="name"
      valueKey="id"
      value={optionValue}
      searchable={true}
      clearable={true}
    />
  )
}

const getLabel = ({ option }: any) => {
  return (
    <React.Fragment>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <div style={{ height: '25px', width: '25px' }}>
          {option.flag && <option.flag />}
        </div>
        <span style={{ marginLeft: '10px' }}>{option.name}</span>
        <span style={{ marginLeft: '10px' }}>- {option.code}</span>
      </div>
    </React.Fragment>
  )
}

export default CountryOfOriginSelect
