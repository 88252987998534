export const palette = {
  alternate: {
    main: 'rgb(247, 249, 250)',
    dark: '#e8eaf6',
  },
  cardShadow: 'rgba(23, 70, 161, .11)',
  type: 'light',
  primary: {
    main: 'rgb(51, 51, 51)',
    light: 'rgb(71, 145, 219)',
    dark: 'rgb(17, 82, 147)',
    contrastText: '#fff',
  },
  secondary: {
    main: '#c0a578',
    light: '#c7af87',
    dark: '#c7af87',
    contrastText: 'rgba(0, 0, 0, 0.87)',
  },
  text: {
    primary: '#2d3748',
    secondary: '#718096',
  },
  divider: 'rgba(0, 0, 0, 0.12)',
  background: {
    paper: '#fff',
    default: 'rgb(247, 249, 250)',
    level2: '#f5f5f5',
    level1: '#fff',
    footer: '#333333',
  },
}
