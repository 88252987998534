import * as isoCountries from 'i18n-iso-countries'

export function getCountryName(
  countryCode: string,
  locale: string,
): string | undefined {
  isoCountries.registerLocale(
    require(`i18n-iso-countries/langs/${locale}.json`),
  )

  try {
    const countryName = isoCountries.getName(countryCode, locale)
    return countryName
  } catch (error) {
    return undefined
  }
}
