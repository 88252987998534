import axios from "axios";
import { styled, useStyletron } from "baseui";
import NoResult from "components/NoResult/NoResult";
import { ARTWORK_SIZES, ListItem, ListItemLabel } from "baseui/list";

const BoxWrapper = styled("div", ({ $theme }) => ({
  padding: "20px",
  boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
  background: "#fff",
  minHeight: "500px",
  maxHeight: "600px",
  overflow: "scroll",
}));
const BoxTitle = styled("h2", ({ $theme }) => ({
  ...$theme.typography.font18,
  margin: 0,
  padding: "10px 0",
  borderBottom: "1px solid rgb(0 0 0 / 12%)",
}));

const ImageWrapper = styled("div", ({ $theme }) => ({
  width: "38px",
  height: "38px",
  overflow: "hidden",
  display: "inline-block",
  borderTopLeftRadius: "20px",
  borderTopRightRadius: "20px",
  borderBottomRightRadius: "20px",
  borderBottomLeftRadius: "20px",
  backgroundColor: $theme.colors.backgroundF7,
}));

const Image = styled("img", () => ({
  width: "100%",
  height: "auto",
}));

export default function NotificationsTable({ data, ...props }) {
  const [css] = useStyletron();
  console.log({ data })
  return (
    <BoxWrapper>
      {data.length ? (
        <>
          <BoxTitle>Notifications</BoxTitle>
          <ul
            className={css({
              paddingLeft: 0,
              paddingRight: 0,
            })}
          >
            {data.map((task, index) => {
              return (
                <ListItem
                  key={index}
                  artworkSize={ARTWORK_SIZES.LARGE}
                  // endEnhancer={(props) => {
                  artwork={(props) => {
                    if (task.image) {
                      return (
                        <ImageWrapper>
                          <Image src={task.image} />
                        </ImageWrapper>
                      );
                    } else {
                      return null;
                    }
                  }}
                >
                  <ListItemLabel description={task.secondary}>
                    {task.primary}
                  </ListItemLabel>
                </ListItem>
              );
            })}
          </ul>
        </>
      ) : (
        <NoResult
          hideButton={true}
          message={"Start your script!"}
          style={{
            gridColumnStart: "1",
            gridColumnEnd: "one",
          }}
        />
      )}
    </BoxWrapper>
  );
}

// ==== TEST DATA FOR DEV PURPOSES
// const data2 = [
//   {
//     primary: "Frist line",
//     secondary: "bottom line",
//     image: "/static/media/no-result.903ca85d.svg",
//   },
//   {
//     primary: "Frist line 2",
//     secondary: "bottom line",
//     image: "/static/media/no-result.903ca85d.svg",
//   },
//   {
//     primary: "Frist line 3",
//     secondary: "bottom line",
//     // image: "/static/media/no-result.903ca85d.svg",
//   },
// ];
