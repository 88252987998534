import { format } from "date-fns";
import React from "react";
import { OrderDate, Time, Year } from "./OrderDate.style";

export const OrderDateComponent = ({ dateTime }) => {
  // console.log("dateTime ->", dateTime);
  const yearDate = format(new Date(dateTime), "yyyy-MM-dd");
  // const yearDate = format(new Date(dateTime), "P");
  const timeDate = format(new Date(dateTime), "kk:mm");

  return (
    <OrderDate>
      <Year>{yearDate}</Year>
      <Time>{timeDate}</Time>
    </OrderDate>
  );
};
