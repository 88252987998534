import { gql, useMutation, useQuery, useReactiveVar } from '@apollo/client'
import ProgressBar from 'components/ProgressBar/ProgressBar'
import DrawerSelect from 'components/Select/DrawerSelect'
import {
  CategoriesMultipleSelectPrimaryCategoryVar,
  CategoriesMultipleSelectValuesVar,
  SiteLanguageVar,
} from 'lib/reactiveVars'
import React, { useEffect, useRef, useState } from 'react'
import { extractProfileByLanguage } from 'utils/languages'

import { StatefulTreeView, TreeLabelInteractable } from 'baseui/tree-view'
import { Checkbox } from 'baseui/checkbox'
import { useInView } from 'react-intersection-observer'
import { Col, Row } from 'components/FlexBox/FlexBox'
import Styles from 'containers/Categories/Categories'
import { styled } from 'baseui'
import { DURATION, useSnackbar } from 'baseui/snackbar'
import { Check } from 'baseui/icon'
import { SIZE, StyledSpinnerNext } from 'baseui/spinner'
import { SizeName } from 'components/ProductCard/ProductCard.style'

// TODO ADD a Creat New Category functionality...

const PrimaryLabel = styled('label', ({ $theme }) => ({
  ...$theme.typography.fontBold14,
  color: $theme.colors.black,
  float: 'right',
  marginRight: '20px',
  zIndex: 100,
}))

const MakePrimaryLabel = styled('label', ({ $theme }) => ({
  ...$theme.typography.font14,
  color: $theme.colors.textDark,
  float: 'right',
  marginRight: '20px',
  cursor: 'pointer',
  zIndex: 100,
  ':hover': {
    textDecoration: 'underline',
  },
}))

export const GET_CATEGORIES = gql`
  query GET_CATEGORIES {
    findManyCategory {
      id
      parent_categoryId
      menu_order
      category_profiles {
        name
        language
      }
    }
  }
`

export const UPDATE_PRODUCT_PRIMARY_CATEGORY = gql`
  mutation UPDATE_PRODUCT_PRIMARY_CATEGORY(
    $where: ProductContainerWhereUniqueInput!
    $primary_categoryId: Int!
  ) {
    updateProductContainerPrimaryCategory(
      where: $where
      primary_categoryId: $primary_categoryId
    ) {
      id
    }
  }
`

const getLabelCheckbox = (category, id, onChange, productContainerId) => () => {
  //============
  const [currentValue, setCurrentValue] = useState(false)
  // Use the var to get the latest info
  const selectedValues = useReactiveVar(CategoriesMultipleSelectValuesVar)
  const primaryCategoryId = useReactiveVar(
    CategoriesMultipleSelectPrimaryCategoryVar,
  )

  // === Site language
  const siteLanguage = useReactiveVar(SiteLanguageVar)
  const languageProfile = extractProfileByLanguage(siteLanguage, category)

  const label = languageProfile
    ? `${languageProfile.name}`
    : `ID: ${id} - (Not translated) - ${siteLanguage.toUpperCase()}`

  // Snackbar
  const { enqueue } = useSnackbar()

  // console.log("selectedValues ->", selectedValues);
  // console.log("currentValue  ->", currentValue);
  // console.log("productContainerId  ->", productContainerId);
  // console.log("primaryCategoryId  ->", primaryCategoryId);

  //* ==== Mutation to edita data in database
  const [
    updateProductContainerPrimaryCategory,
    {
      data: mutationData,
      called,
      loading: mutationLoading,
      error: mutationError,
    },
  ] = useMutation(UPDATE_PRODUCT_PRIMARY_CATEGORY, {
    onError: (e) => {
      // For mutation errors...
      console.log('Mutation error!', e)
      console.log('Extracted error!', e.graphQLErrors)
      enqueue(
        {
          message: 'Error!',
          startEnhancer: ({ size }) => <Check size={size} />,
          overrides: {
            Message: {
              style: ({ $theme }) => ({
                color: $theme.colors.red400,
              }),
            },
            StartEnhancerContainer: {
              style: ({ $theme }) => ({
                color: $theme.colors.red400,
              }),
            },
          },
        },
        DURATION.short,
      )
    },
    onCompleted: (e) => {
      // Handle the success case.
      enqueue(
        {
          message: 'Primary Category Updated!',
          startEnhancer: ({ size }) => <Check size={size} />,
          overrides: {
            Message: {
              style: ({ $theme }) => ({
                color: $theme.colors.lightGreen,
              }),
            },
            StartEnhancerContainer: {
              style: ({ $theme }) => ({
                color: $theme.colors.lightGreen,
              }),
            },
          },
        },
        DURATION.short,
      )
    },
    refetchQueries: [
      'GET_SINGLE_PRODUCT_CONTAINER',
      'GET_ALL_PARENT_CATEGORIES',
    ],
  })

  useEffect(() => {
    if (selectedValues.filter((e) => e.id === id).length > 0) {
      /* vendors contains the element we're looking for */
      setCurrentValue(true)
    } else {
      setCurrentValue(false)
    }
  }, [selectedValues])

  const handleChange = (e) => {
    setCurrentValue((e.target as HTMLInputElement).checked)
    // console.log("We have selected ->", id);
    // console.log("currentValue ->", currentValue);

    let newValuesLists = [...selectedValues]
    // We reversi this, cause at this point, current value has been changed...
    if (!currentValue) {
      // console.log("Selecting");
      // If true we push
      newValuesLists.push({ id: id })
    } else {
      // console.log("Deselecting");
      // if false, we remove this id from list
      newValuesLists = newValuesLists.filter((item) => {
        if (item.id != id) {
          return item
        }
      })
    }
    onChange(newValuesLists)
  }

  const handleMakePrimary = (e) => {
    updateProductContainerPrimaryCategory({
      variables: {
        where: { id: productContainerId },
        primary_categoryId: id,
      },
    })
  }

  return (
    <TreeLabelInteractable>
      <Checkbox
        name={currentValue ? 'selected' : 'not-selected'}
        checked={currentValue}
        onChange={(e) => handleChange(e)}
        overrides={{
          Label: {
            style: ({ $theme }) => ({
              width: '100%',
            }),
          },
        }}
      >
        <Row>
          <Col lg={8}>{label}</Col>
          <Col lg={4} style={{ textAlign: 'right' }}>
            {id === primaryCategoryId ? (
              <PrimaryLabel>Primary</PrimaryLabel>
            ) : (
              currentValue && (
                <>
                  {mutationLoading && (
                    <StyledSpinnerNext
                      style={{
                        display: 'inline-block',
                        height: '20px',
                        width: '20px',
                        marginRight: '10px',
                      }}
                    />
                  )}
                  {productContainerId && (
                    <MakePrimaryLabel onClick={handleMakePrimary}>
                      <a>Make primary</a>
                    </MakePrimaryLabel>
                  )}
                </>
              )
            )}
          </Col>
        </Row>
      </Checkbox>
    </TreeLabelInteractable>
  )
}

// ================== MAIN COMPONENT ==============================
const MultipleCategorySelect = ({
  onChange,
  value,
  productContainerId,
  primaryCategoryId,
}) => {
  // Set the Reactive var for this component

  const { loading, error, data } = useQuery(GET_CATEGORIES)

  useEffect(() => {
    if (value && data) {
      CategoriesMultipleSelectValuesVar(value)
      CategoriesMultipleSelectPrimaryCategoryVar(primaryCategoryId)
    }
  }, [value, data, primaryCategoryId])

  if (loading) return <ProgressBar infinite={true} value={100} />
  if (error) return <p>Query Error! {error.message}</p>

  let allCategories = [...JSON.parse(JSON.stringify(data.findManyCategory))]
  const sortedByMenuOrder = allCategories.sort(
    (a, b) => a.menu_order - b.menu_order,
  )
  const sortedCategories = sortCategories(sortedByMenuOrder)

  // console.log('sortedCategories :>> ', sortedCategories)

  const tableDataContent = CrunchCategoryData(
    sortedCategories,
    value,
    onChange,
    productContainerId,
  )

  return (
    <div
      // ref={ref}
      id={'multiple-categories-container'}
      style={{ maxHeight: 300, overflow: 'scroll' }}
    >
      <StatefulTreeView data={tableDataContent} />
    </div>
  )
}

const CrunchCategoryData = (catData, value, onChange, productContainerId) => {
  let crunchData = [] as any
  for (const category of catData) {
    const nestedCategory = crunchSingleCategory(
      category,
      value,
      onChange,
      productContainerId,
    )
    crunchData.push(nestedCategory)
  }

  return crunchData
}

const crunchSingleCategory = function (
  category,
  value,
  onChange,
  productContainerId,
) {
  if (category.child_categories.length === 0) {
    const catObject = {
      id: category.id,
      label: getLabelCheckbox(
        category.category_profiles,
        category.id,
        onChange,
        productContainerId,
      ),
      isExpanded: true,
      children: [],
    }
    return catObject
  }

  let childrenList = [] as any
  for (const childCat of category.child_categories) {
    if (category.child_categories.length > 0) {
      const childOfChild = crunchSingleCategory(
        childCat,
        value,
        onChange,
        productContainerId,
      )
      childrenList.push(childOfChild)
    } else {
      const childCatObject = {
        id: childCat.id,
        label: getLabelCheckbox(
          childCat.category_profiles,
          childCat.id,
          onChange,
          productContainerId,
        ),
        isExpanded: true,
        children: [],
      }
      return childCatObject
    }
  }

  const parentCatObject = {
    id: category.id,
    label: getLabelCheckbox(
      category.category_profiles,
      category.id,
      onChange,
      productContainerId,
    ),
    isExpanded: true,
    children: childrenList,
  }
  return parentCatObject
}

function sortCategories(categories, parentId = null) {
  const sortedCategories = []
  categories.forEach((category) => {
    if (category.parent_categoryId === parentId) {
      const sortedCategory = { ...category }
      sortedCategory.child_categories = sortCategories(categories, category.id)
      sortedCategories.push(sortedCategory)
    }
  })
  return sortedCategories
}

export default MultipleCategorySelect
