import React, { useEffect, useState, useCallback } from 'react'
import { useDropzone } from 'react-dropzone'
import { styled } from 'baseui'
import { UploadIcon } from 'assets/icons/UploadIcon'
import { gql, useMutation, useReactiveVar } from '@apollo/client'
import Button, { SHAPE } from 'components/Button/Button'
import { Delete, DeleteAlt } from 'baseui/icon'
import { StyledSpinnerNext } from 'baseui/spinner'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import ReactDOM from 'react-dom'
import { collapseTextChangeRangesAcrossMultipleVersions } from 'typescript'
import { arraysEqual } from 'utils'
import { ImagesCurrentlyUploadingVar } from 'lib/reactiveVars'
import { Box } from 'components/Box/Box'
import { Label4 } from 'baseui/typography'

const Text = styled('span', ({ $theme }) => ({
  ...$theme.typography.font14,
  fontFamily: $theme.typography.primaryFontFamily,
  color: $theme.colors.textDark,
  marginTop: '15px',
  textAlign: 'center',
}))

const TextHighlighted = styled('span', ({ $theme }) => ({
  color: $theme.colors.primary,
  fontWeight: 'bold',
}))

const Container = styled('div', ({ props }) => ({
  flex: '1',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '30px',
  borderWidth: '2px',
  borderRadius: '2px',
  borderColor: '#E6E6E6',
  borderStyle: 'dashed',
  backgroundColor: '#ffffff',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border 0.24s ease-in-out',
  cursor: 'pointer',
  marginBottom: '10px',
}))

// const ThumbsContainer = styled("aside", () => ({
//   display: "flex",
//   flexDirection: "row",
//   flexWrap: "wrap",
//   marginTop: "16px",
//   width: "100%",
// }));

const Thumb = styled('div', ({ $theme }) => ({
  ...$theme.borders.borderEA,
  display: 'inline-flex',
  borderRadius: '2px',
  marginBottom: '8px',
  marginRight: '8px',
  width: '200px',
  height: '200px',
  padding: '4px',
  boxSizing: 'border-box',
  position: 'relative',

  justifyContent: 'space-evenly',
  alignItems: 'center',
}))

const thumbInner = {
  display: 'flex',
  overflow: 'hidden',
  width: '100%',
  height: '100%',
}

const DeleteButton = styled('div', ({ $theme }) => ({
  position: 'absolute',
  right: 0,
  top: 0,
  color: $theme.colors.red400,
  ':hover': {
    color: $theme.colors.primary400,
    cursor: 'pointer',
  },
}))

const img = {
  display: 'block',
  width: 'auto',
  height: '100%',
}

const ThumbContainer = styled('div', ({ props }) => ({
  flexGrow: 1,
  display: 'inline-flex',
}))

const ScrollContainer = styled('div', ({ props }) => ({
  overflow: 'auto',
  padding: '15px 5px',
}))

const DropZone = styled('div', ({ props }) => ({
  display: 'flex',
  alignItems: 'start',
  minWidth: 'px',
  minHeight: '100px',
}))

const Wrapper = styled('div', ({ props }) => ({
  display: 'flex',
  flexDirection: 'column',
  userSelect: 'none',
  transition: 'background-color 0.1s ease',
}))

const UPLOAD_FILE = gql`
  mutation UPLOAD_FILE($file: Upload!) {
    uploadFile(file: $file)
  }
`

// ====== PORTAL CREATION FOR REACT DND ========
//@ts-ignore
const portal: HTMLElement = document.createElement('div')
portal.classList.add('my-super-cool-portal')
if (!document.body) {
  throw new Error('body not ready for portal creation!')
}
document.body.appendChild(portal)

// TODO we could apply an image editor to each image (example in react-dropzone documentation - integrations)
// --- https://react-dropzone.js.org/#section-extending-dropzone
//--------------------
function Uploader({
  onChange,
  images,
  multiple = true,
  hideDropContainerIfImageAdded = false,
}: any) {
  const [files, setFiles] = useState(images ? images : [])
  const [errors, setErrors] = useState([])

  const { getRootProps, getInputProps, fileRejections } = useDropzone({
    // accept: 'image/*',
    accept: {
      'image/jpg': [],
      'image/jpeg': [],
      'image/png': [],
    },
    multiple: multiple,
    // maxFiles: 2, // If we need to limit it
    onDrop: (acceptedFiles) => {
      if (acceptedFiles.length > 0) {
        if (multiple) {
          let newFiles = [...acceptedFiles]
          if (files.length > 0) {
            newFiles = [...files, ...newFiles]
          }
          setFiles(newFiles)
        } else {
          // For single files...
          setFiles(acceptedFiles)
        }
        setErrors([])
        ImagesCurrentlyUploadingVar(true)
      }
    },
    onError: (error) => {
      console.log('error (dropzone) :>> ', error)
    },
    onDropRejected: (rejectedFiles) => {
      const errorMessages = rejectedFiles.map((item) => item.errors[0].message)
      setErrors(errorMessages)
    },
  })

  const onRemove = (index) => {
    let tempFiles = [...files]
    tempFiles.splice(index, 1)
    setFiles(tempFiles)
  }

  const onNewImageUrl = (index, url) => {
    let tempFiles = [...files]
    tempFiles[index]['src'] = url
    setFiles(tempFiles)
  }

  useEffect(() => {
    // Hanndle image update on parent component
    let parentImages = []

    for (const url of images || []) {
      const file = {
        src: url,
      }
      parentImages.push(file)
    }

    if (arraysEqual(files, parentImages)) {
      // Skip
      // console.log("Skipping...");
    } else {
      setFiles(parentImages)
    }
  }, [images])

  useEffect(() => {
    let imagesToSendBack = []
    for (const file of files) {
      if (file.src) {
        imagesToSendBack.push(file.src)
      }
    }

    // --> OnChange passes a file to a component above
    // if (imagesToSendBack[0] === images[0]) {
    if (arraysEqual(imagesToSendBack, images)) {
      // skip
      // console.log("Skipping 2...");
    } else {
      // If all images finished uploading, then we perform the state update
      if (files.length === imagesToSendBack.length) {
        onChange(imagesToSendBack)
        ImagesCurrentlyUploadingVar(false)
      }
    }
  }, [files])

  // ==== REACT DND FUNCTIONS (Drag n drop) ========
  const grid = 5
  const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    margin: `0 ${grid}px 0 0`,
    background: isDragging ? 'lightgreen' : 'white',
    ...draggableStyle,
  })

  const getListStyle = (isDraggingOver) => ({
    background: isDraggingOver ? 'lightblue' : 'lightgrey',
    display: 'flex',
  })

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return
    }
    const newFiles = reorder(
      files,
      result.source.index,
      result.destination.index,
    )

    setFiles(newFiles)
  }

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)
    return result
  }

  const showDropContainer = hideDropContainerIfImageAdded
    ? files.length === 0
    : true

  return (
    <section className="container uploader">
      {showDropContainer && (
        <Container {...getRootProps()}>
          <input {...getInputProps()} />
          <UploadIcon />
          <Text>
            <TextHighlighted>Drag/Upload</TextHighlighted> your image here.
          </Text>
        </Container>
      )}
      {files.length > 0 && (
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable
            droppableId="droppable"
            direction="horizontal"
            ignoreContainerClipping={true}
          >
            {(provided, snapshot) => (
              <Wrapper
                {...provided.droppableProps}
                style={getListStyle(snapshot.isDraggingOver)}
              >
                <ScrollContainer>
                  <ThumbContainer>
                    <DropZone ref={provided.innerRef}>
                      {files.map((file, i) => {
                        return (
                          <Draggable
                            key={i}
                            draggableId={(i + 1).toString()}
                            index={i}
                          >
                            {(provided, snapshot) => (
                              <ImageThumbnailUpload
                                style={getItemStyle(
                                  snapshot.isDragging,
                                  provided.draggableProps.style,
                                )}
                                provided={provided}
                                snapshot={snapshot}
                                key={i}
                                file={file}
                                index={i}
                                onRemove={onRemove}
                                onNewImageUrl={onNewImageUrl}
                              />
                            )}
                          </Draggable>
                        )
                      })}
                      {provided.placeholder}
                    </DropZone>
                  </ThumbContainer>
                </ScrollContainer>
              </Wrapper>
            )}
          </Droppable>
        </DragDropContext>
      )}
      <Box>
        {errors.map((errorMessage, i) => (
          <Label4 key={i} color="red">
            {errorMessage}
          </Label4>
        ))}
      </Box>
    </section>
  )
}

const ImageThumbnailUpload = ({
  file,
  index,
  onRemove,
  onNewImageUrl,
  snapshot,
  provided,
  style,
}) => {
  const image = file.src
  // ========== FILE UPLOAD =========
  const [uploadFile, { data, loading }] = useMutation(UPLOAD_FILE, {
    onError: (e) => {
      // For mutation errors...
      console.log('Upload Mutation error:', e)
      console.log('Extracted -', e.graphQLErrors)
    },
    onCompleted: (e) => {
      // Handle the success case.
      const imageUrl = e.uploadFile
      onNewImageUrl(index, imageUrl)
    },
  })
  // ========================

  useEffect(() => {
    // We want to initiate server side upload and only pass the string of an uploaded url.
    // Initiate serverside upload here.

    let fileContainsURL = false
    if (typeof file === 'string') {
      fileContainsURL =
        file.substring(0, 7) !== 'http://' ||
        file.substring(0, 7) !== 'https://'
          ? true
          : false
    }

    if (!file.src && !fileContainsURL) {
      // Initiate the upload only if file has no soruce.
      uploadFile({
        variables: {
          file,
        },
      })
    } else {
      // console.log('Skipping upload ->', file.src)
    }
  }, [file])

  const usePortal: boolean = snapshot.isDragging

  const child = (
    <Thumb
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      // inPortal={usePortal}
      style={style}
    >
      <DeleteButton
        onClick={(e) => {
          onRemove(index)
        }}
      >
        <DeleteAlt size={30} />
      </DeleteButton>
      {loading ? (
        <StyledSpinnerNext id="image-loading" />
      ) : (
        <ImageThumb image={image} />
      )}
    </Thumb>
  )

  if (!usePortal) {
    return child
  }

  // if dragging - put the item in a portal
  return ReactDOM.createPortal(child, portal)
}

const ImageThumb = ({ image }) => {
  let imageLink = null
  const isValidUrl = validURL(image)

  if (isValidUrl) {
    imageLink = image
  }

  return (
    <div
      style={{
        background: `url("${image}") center center/contain no-repeat`,
        ...thumbInner,
      }}
    >
      {imageLink && (
        <a href={imageLink} target="_blank">
          Enlarge
        </a>
      )}
    </div>
  )
}

function validURL(str) {
  var pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i',
  ) // fragment locator
  return !!pattern.test(str)
}

export default Uploader
