import { gql } from "@apollo/client"

export const GET_FABRIC_PALETTES = gql`
  query GET_FABRIC_PALETTES(
    $searchTerm: String
    $take: Int
    $skip: Int
    $orderBy: [FabricPaletteOrderByWithRelationInput!]
  ) {
    findManyFabricPaletteCount(
      where: {
        OR: [
          { name: { mode: insensitive, contains: $searchTerm } }
        ]
      }
    )

    findManyFabricPalette(
      orderBy: $orderBy
      where: {
        OR: [
          { name: { mode: insensitive, contains: $searchTerm } }
        ]
      }
      take: $take
      skip: $skip
    ) {
      id
      name
      createdAt
      # customer_groups {
      #   name
      #   id
      # }
      _count {
        fabric_groups
      }
      # fabric_groups {
      #   code
      #   id
      #   fabric_group_profiles {
      #     name
      #     language
      #   }
      # }
    }
  }
`

export const DELETE_SINGLE_FABRIC_PALETTE = gql`
  mutation DELETE_SINGLE_FABRIC_PALETTE($id: Int!) {
       deleteOneFabricPalette(where: { id: $id}) {
      id
    }
  }
`

export const CORE_FABRIC_PALETTE_DATA = gql`
  fragment CoreFabricPaletteData on FabricPalette {
    id
    createdAt

    name
    fabric_groups {
      id
    }
  }
`

//  TODO ======
export const CREATE_FABRIC_PALETTE = gql`
  ${CORE_FABRIC_PALETTE_DATA}
  mutation CREATE_FABRIC_PALETTE(
    $name: String!
    $fabric_groups: [Int]
  ) {
    createFabricPalette(
      name: $name
      fabric_groups: $fabric_groups
    ) {
      ...CoreFabricPaletteData
    }
  }
`

export const UPDATE_FABRIC_PALETTE = gql`
  ${CORE_FABRIC_PALETTE_DATA}
  mutation UPDATE_FABRIC_PALETTE(
    $where: FabricPaletteWhereUniqueInput!
    $name: String!
    $fabric_groups: [Int]
  ) {
    updateFabricPalette(
      where: $where
      name: $name
      fabric_groups: $fabric_groups
    ) {
      ...CoreFabricPaletteData
    }
  }
`

export const GET_FABRIC_PALETTE = gql`
  ${CORE_FABRIC_PALETTE_DATA}
  query GET_FABRIC_PALETTE($id: Int!) {
    findUniqueFabricPalette(where: { id: $id }) {
      ...CoreFabricPaletteData
    }
  }
`