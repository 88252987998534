import { gql, useLazyQuery } from '@apollo/client'
import { LoginErrorVar, LoginSuccessVar } from 'lib/reactiveVars'
import React, { useEffect } from 'react'

type AuthProps = {
  isAuthenticated: boolean
  authenticate: Function
  demoAuthenticate: Function
  signout: Function
}

export const LOGIN_QUERY = gql`
  query LOGIN_QUERY($email: String!, $password: String!) {
    loginQuery(email: $email, password: $password)
  }
`

export const DEMO_LOGIN_QUERY = gql`
  query DEMO_LOGIN_QUERY(
    $name: String!
    $email: String!
    $phone: String
    $privacyPolicy: Boolean!
    $username: String!
    $password: String!
  ) {
    demoLoginQuery(
      name: $name
      email: $email
      phone: $phone
      privacyPolicy: $privacyPolicy
      username: $username
      password: $password
    )
  }
`
export const AuthContext = React.createContext({} as AuthProps)

const isValidToken = () => {
  const token = localStorage.getItem('furnisystems_token')
  // JWT decode & check token validity & expiration.
  if (token) return true
  return false
}

const AuthProvider = (props: any) => {
  const [isAuthenticated, makeAuthenticated] = React.useState(isValidToken())

  // === GRAPHQL Query to get LOGIN
  const [loginQueryLazy, { loading, error, data: queryData }] = useLazyQuery(
    LOGIN_QUERY,
    {
      onError: (e) => {
        // For mutation errors...
        console.log('Login error!', e)
        console.log('Extracted error!', e.graphQLErrors)
        LoginErrorVar(true)
        LoginSuccessVar(false)
      },
      onCompleted: (e) => {
        // console.log("Login completed...");
        const token = queryData.loginQuery
        // console.log("We have token ->", token);
        makeAuthenticated(true)
        localStorage.setItem('furnisystems_token', `${token}`)
        LoginErrorVar(false)
        LoginSuccessVar(true)
      },
    },
    // -- Turning off, as referch queries are a lot more efficient
    // fetchPolicy: "cache-and-network",
  )

  // === GRAPHQL Query to get DEMO LOGIN
  const [
    loginDemoQueryLazy,
    { loading: loadingDemo, error: errorDemo, data: queryDemoData },
  ] = useLazyQuery(
    DEMO_LOGIN_QUERY,
    {
      onError: (e) => {
        // For mutation errors...
        console.log('DEMO Login error!', e)
        console.log('Extracted error!', e.graphQLErrors)
        LoginErrorVar(true)
        LoginSuccessVar(false)
      },
      onCompleted: (e) => {
        // console.log("Login completed...");
        const token = queryDemoData.demoLoginQuery
        // console.log("We have token ->", token);
        makeAuthenticated(true)
        localStorage.setItem('furnisystems_token', `${token}`)
        LoginErrorVar(false)
        LoginSuccessVar(true)
      },
    },
    // -- Turning off, as referch queries are a lot more efficient
    // fetchPolicy: "cache-and-network",
  )

  async function authenticate({ username, password }) {
    // Check if username and pass matches agains env
    // console.log('username -->', username)
    // console.log("password -->", password);
    loginQueryLazy({
      variables: {
        email: username,
        password: password,
      },
    })
  }

  async function demoAuthenticate({
    name,
    email,
    phone,
    privacyPolicy,
    username,
    password,
  }) {
    // Check if username and pass matches agains env
    // console.log('username -->', username)
    // console.log("password -->", password);
    loginDemoQueryLazy({
      variables: {
        name: name,
        email: email,
        phone: phone,
        privacyPolicy: privacyPolicy,
        username: username,
        password: password,
      },
    })
  }

  function signout() {
    makeAuthenticated(false)
    LoginSuccessVar(false)
    localStorage.removeItem('furnisystems_token')
  }

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        authenticate,
        demoAuthenticate,
        signout,
      }}
    >
      <>{props.children}</>
    </AuthContext.Provider>
  )
}

export default AuthProvider
