import React, { useContext, lazy, Suspense } from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import {
  LOGIN,
  PRODUCTS,
  CATEGORIES,
  COLORS,
  MANUFACTURERS,
  DASHBOARD,
  ORDERS,
  SETTINGS,
  CUSTOMERS,
  COUPONS,
  STAFF_MEMBERS,
  SITE_SETTINGS,
  BLOG,
  WOO_IMPORT,
  STYLES,
  COLLECTIONS,
  DISCOUNTS,
  PRODUCT_POSITION_SCRIPT,
  LANGUAGE_TRANSLATE_SCRIPT,
  FACEBOOK_FEED_SCRIPT,
  IMAGE_DOWNLOAD_SCRIPT,
  IMAGE_RESIZE_SCRIPT,
  TAGS,
  IMPORT_SCRIPT,
  CLEAN_UP_SCRIPT,
  FABRIC_GROUPS,
  GENERAL_SETTINGS,
  PRIVACY_POLICY_FORM,
  RETURNS_POLICY_FORM,
  HOME_PAGE_FORM,
  ABOUT_US_PAGE_FORM,
  CONTACT_US_PAGE_FORM,
  GENERAL_SETTINGS_SHORTER,
  COMMERCIAL_OFFERS,
  CONTENT_BLOCKS,
  REVALIDATE_SCRIPT,
  DESIGN_SETTINGS,
  B2B_SETTINGS,
  B2B_CUSTOMERS,
  ACCOUNT_MANAGERS,
  EMBED_CONFIGURATOR_SETTINGS,
  CUSTOMER_GROUPS,
  FABRIC_PALETTES,
  PRICELISTS,
} from 'settings/constants'
import AuthProvider, { AuthContext } from 'context/auth'
import { InLineLoader } from 'components/InlineLoader/InlineLoader'
import Blog from 'containers/Blog/Blog'
import WOOImport from 'containers/WOOImport/WOOImport'
import ProductPositionScript from 'containers/ProductPositionScript/ProductPositionScript'
import LanguageTranslateScript from 'containers/LanguageTranslateScript/LanguageTranslateScript'
import FacebookFeedScript from 'containers/FacebookFeedScript/FacebookFeedScript'
import ImageDownloadScript from 'containers/ImageDownloadScript/ImageDownloadScript'
import ImageResizeScript from 'containers/ImageResizeScript/ImageResizeScript'
import CleanUpScript from 'containers/CleanUpScript/CleanUpScript'
import DataImport from 'containers/DataImport/DataImportScript'
import RevalidateScript from 'containers/RevalidateScript/RevalidateScript'
import DesignSettingsForm from 'containers/SiteSettingForm/DesignSettingsForm'
import B2BSettingsForm from 'containers/SiteSettingForm/B2BSettingsForm'
import EmbedConfiguratorSettingsForm from 'containers/SiteSettingForm/EmbedConfiguratorSettingsForm'
import B2BCustomersPage from 'containers/B2BCustomers/B2BCustomers'
import AccountmanagersPage from 'containers/AccountManagers/AccountManagers'
import CustomerGroupsPage from 'containers/CustomerGroups/CustomerGroups'
import PriceListsPage from 'containers/PriceLists/PriceLists'
import FabricPalettes from 'containers/FabricPaletes/FabricPalettes'

const FabricGroups = lazy(() => import('containers/FabricGroups/FabricGroups'))
const Colors = lazy(() => import('containers/Colors/Colors'))
const Manufacturers = lazy(
  () => import('containers/Manufacturers/Manufacturers'),
)
const Styles = lazy(() => import('containers/Styles/Styles'))
const ContentBlocks = lazy(
  () => import('containers/ContentBlocks/ContentBlocks'),
)

const Collections = lazy(() => import('containers/Collections/Collections'))
const Products = lazy(() => import('containers/Products/Products'))
const AdminLayout = lazy(() => import('containers/Layout/Layout'))
const Dashboard = lazy(() => import('containers/Dashboard/Dashboard'))
const Categories = lazy(() => import('containers/Categories/Categories'))
const Orders = lazy(() => import('containers/Orders/Orders'))

const CommercialOffers = lazy(
  () => import('containers/CommercialOffers/CommercialOffers'),
)

const StaffMembers = lazy(() => import('containers/StaffMembers/StaffMembers'))
const Customers = lazy(() => import('containers/Customers/Customers'))
const Coupons = lazy(() => import('containers/Coupons/Coupons'))
const Discounts = lazy(() => import('containers/Discounts/Discounts'))

const Login = lazy(() => import('containers/Login/Login'))
const NotFound = lazy(() => import('containers/NotFound/NotFound'))
const Tags = lazy(() => import('containers/Tags/Tags'))

// Settings imports -----
const Settings = lazy(() => import('containers/Settings/Settings'))
const SiteSettingForm = lazy(
  () => import('containers/SiteSettingForm/SiteSettingForm'),
)
const SiteSettingFormShorter = lazy(
  () => import('containers/SiteSettingForm/SiteSettingFormShorter'),
)
const SiteSettings = lazy(() => import('containers/Settings/SiteSettings'))
const PrivacyPolicyForm = lazy(
  () => import('containers/SiteSettingForm/PrivacyPolicyForm'),
)
const ReturnsPolicyForm = lazy(
  () => import('containers/SiteSettingForm/ReturnsPolicyForm'),
)
const HomePageForm = lazy(
  () => import('containers/SiteSettingForm/HomePageForm'),
)
const ContactUsForm = lazy(
  () => import('containers/SiteSettingForm/ContactUsForm'),
)
const AboutUsForm = lazy(() => import('containers/SiteSettingForm/AboutUsForm'))
/**
 *
 *  A wrapper for <Route> that redirects to the login
 * screen if you're not yet authenticated.
 *
 */

const PDFViewerComponent = lazy(
  () => import('features/commercialOfferDataCrunch'),
)

function PrivateRoute({ children, ...rest }) {
  const { isAuthenticated } = useContext(AuthContext)

  return (
    <Route
      {...rest}
      render={({ location }) =>
        isAuthenticated ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location },
            }}
          />
        )
      }
    />
  )
}

const Routes = () => {
  return (
    <AuthProvider>
      <Suspense fallback={<InLineLoader />}>
        <Switch>
          <PrivateRoute path={PRODUCTS}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <Products />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute exact={true} path={DASHBOARD}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <Dashboard />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>

          <PrivateRoute path={CATEGORIES}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <Categories />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={COLORS}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <Colors />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={MANUFACTURERS}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <Manufacturers />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={STYLES}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <Styles />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={CONTENT_BLOCKS}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <ContentBlocks />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={TAGS}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <Tags />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={COLLECTIONS}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <Collections />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={ORDERS}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <Orders />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={COMMERCIAL_OFFERS}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <CommercialOffers />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={CUSTOMERS}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <Customers />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={ACCOUNT_MANAGERS}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <AccountmanagersPage />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={CUSTOMER_GROUPS}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <CustomerGroupsPage />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={PRICELISTS}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <PriceListsPage />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={BLOG}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <Blog />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={COUPONS}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <Coupons />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={DISCOUNTS}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <Discounts />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={SETTINGS}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <Settings />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={STAFF_MEMBERS}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <StaffMembers />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={SITE_SETTINGS}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <SiteSettings />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={GENERAL_SETTINGS}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <SiteSettingForm />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>

          <PrivateRoute path={GENERAL_SETTINGS_SHORTER}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <SiteSettingFormShorter />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>

          <PrivateRoute path={WOO_IMPORT}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <WOOImport />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={PRODUCT_POSITION_SCRIPT}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <ProductPositionScript />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={LANGUAGE_TRANSLATE_SCRIPT}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <LanguageTranslateScript />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={FACEBOOK_FEED_SCRIPT}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <FacebookFeedScript />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={IMAGE_DOWNLOAD_SCRIPT}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <ImageDownloadScript />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={IMAGE_RESIZE_SCRIPT}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <ImageResizeScript />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>

          <PrivateRoute path={IMPORT_SCRIPT}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <DataImport />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>

          <PrivateRoute path={CLEAN_UP_SCRIPT}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <CleanUpScript />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>

          <PrivateRoute path={FABRIC_GROUPS}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <FabricGroups />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>

          <PrivateRoute path={FABRIC_PALETTES}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <FabricPalettes />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>

          <PrivateRoute path={PRIVACY_POLICY_FORM}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <PrivacyPolicyForm />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>

          <PrivateRoute path={RETURNS_POLICY_FORM}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <ReturnsPolicyForm />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={HOME_PAGE_FORM}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <HomePageForm />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>

          <PrivateRoute path={CONTACT_US_PAGE_FORM}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <ContactUsForm />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={ABOUT_US_PAGE_FORM}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <AboutUsForm />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={REVALIDATE_SCRIPT}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <RevalidateScript />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>

          <PrivateRoute path={DESIGN_SETTINGS}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <DesignSettingsForm />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>

          <PrivateRoute path={B2B_SETTINGS}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <B2BSettingsForm />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>

          <PrivateRoute path={EMBED_CONFIGURATOR_SETTINGS}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <EmbedConfiguratorSettingsForm />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>

          <PrivateRoute path={B2B_CUSTOMERS}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <B2BCustomersPage />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>

          <PrivateRoute path={'/pdf-viewer'}>
            <Suspense fallback={<InLineLoader />}>
              {/* <PDFViewerComponent /> */}
            </Suspense>
          </PrivateRoute>

          <Route path={LOGIN}>
            <Login />
          </Route>
          <Route component={NotFound} />
        </Switch>
      </Suspense>
    </AuthProvider>
  )
}

export default Routes
