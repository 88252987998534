import React from 'react'
import { Block } from 'baseui/block'

const DrawerBox = ({ overrides, children, sameHeight, noBackground }: any) => {
  return (
    <Block
      overrides={{
        Block: {
          style: {
            width: '100%',
            height: sameHeight ? '100%' : 'auto',
            padding: noBackground ? '30px 30px 10px 30px' : '30px',
            borderRadius: '3px',
            backgroundColor: noBackground ? null : '#ffffff',
          },
        },
        ...overrides,
      }}
    >
      {children}
    </Block>
  )
}

export default DrawerBox
