import { useState } from "react";


export default function usePaging(defaultPageNumber: number, defaultItemsPerPage: number) {

    const [pageNumber, onChangePage] = useState(defaultPageNumber || 1)
    const [paginationPerPage, setRowsPerPage] = useState(defaultItemsPerPage)
    const [resetPaginationToggle, setResetPaginationToggle] = useState(undefined)


    const getFilters = () => {
        return {
            skip: (pageNumber - 1) * paginationPerPage,
            take: paginationPerPage,
        }
    }

    const onChangeRowsPerPage = (rowsPerPage, page) => {
        onChangePage(page)
        setRowsPerPage(rowsPerPage)
        setResetPaginationToggle(!resetPaginationToggle)
    }

    return {
        pageNumber,
        paginationPerPage,
        onChangeRowsPerPage,
        onChangePage,
        getFilters,
        resetPaginationToggle,
    }
}