import { styled, withStyle } from "baseui";

export const OrderDate = styled("div", () => ({
  // ----
  display: "flex",
  flexWrap: "wrap",
}));

export const Year = styled("span", ({ $theme }) => ({
  // ----
  color: $theme.colors.textDark,
  width: "100%",
}));

export const Time = styled("span", ({ $theme }) => ({
  // ----
  color: "rgb(0 0 0 / 50%)",
  width: "100%",
}));
