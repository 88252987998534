import React, { useCallback, useEffect, useState } from 'react'

import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalButton,
  SIZE,
  ROLE,
} from 'baseui/modal'
import { KIND as ButtonKind } from 'baseui/button'
import { styled } from 'baseui'
import { Select, Value } from 'baseui/select'
import { expandBorderStyles } from 'baseui/styles'
import DrawerSelect from 'components/Select/DrawerSelect'

export interface ConfirmationOptions {
  catchOnCancel?: boolean
  variant: 'danger' | 'info'
  title: string
  description: string
  type?:
    | 'WARNING'
    | 'COLOR_SELECT'
    | 'BOOLEAN_SELECT'
    | 'CATEGORY_SELECT'
    | 'NUMBER_INPUT'
    | 'COUNTRY_OF_ORIGIN_SELECT'
    | 'SHIPPING_METHOD_SELECT'
  selectionOptions?: Array<selectionOptions> | undefined | null
}

interface ConfirmationDialogProps extends ConfirmationOptions {
  open: boolean
  onSubmit: (value) => any
  onClose: () => void
}

interface selectionOptions {
  id: number
  label: string
  color: string
}

export const WarningModal: React.FC<ConfirmationDialogProps> = ({
  open,
  title,
  variant,
  description,
  selectionOptions,
  onSubmit,
  onClose,
}) => {
  // console.log("We have selection options ->", selectionOptions);
  const [value, setValue] = useState(null)

  // Reset modal value...
  useEffect(() => {
    setValue(null)
  }, [open])

  // --> This only appears if we pass the selectionOptions (this must include label and id, optionally color)
  let selectionComponent = null
  if (selectionOptions) {
    selectionComponent = (
      <Select
        backspaceRemoves={false}
        options={selectionOptions}
        value={value}
        searchable={false}
        placeholder="Select item"
        onChange={(params) => {
          setValue(params.value)
        }}
        getOptionLabel={getLabel}
        getValueLabel={getLabel}
        overrides={{
          Popover: {
            props: {
              overrides: {
                Body: {
                  style: { zIndex: 200 },
                },
              },
            },
          },
        }}
      />
    )
  }

  let isButtonDisabled = false
  if (selectionComponent) {
    //----
    if (value === null) {
      isButtonDisabled = true
    }
  }

  return (
    <Modal
      onClose={onClose}
      closeable
      isOpen={open}
      animate
      autoFocus
      size={SIZE.default}
      role={ROLE.dialog}
      overrides={{
        Root: {
          style: {
            zIndex: 100,
          },
        },
      }}
    >
      <ModalHeader>{title}</ModalHeader>
      <ModalBody>
        <p>{description}</p>
        {selectionComponent}
      </ModalBody>
      <ModalFooter>
        {variant === 'danger' && (
          <>
            <ModalButton
              kind={ButtonKind.tertiary}
              onClick={onClose}
              overrides={{
                BaseButton: {
                  style: ({ $theme }) => ({
                    color: $theme.colors.red400,
                  }),
                },
              }}
            >
              Cancel
            </ModalButton>
            <ModalButton
              onClick={(e) => onSubmit(value)}
              disabled={!value && selectionComponent} // IF selection component and no value, then disabled...
              overrides={{
                BaseButton: {
                  style: ({ $theme }) => ({
                    backgroundColor: $theme.colors.red400,
                  }),
                },
              }}
            >
              Yes
            </ModalButton>
          </>
        )}

        {variant === 'info' && (
          <ModalButton
            disabled={isButtonDisabled}
            onClick={(e) => onSubmit(value)}
          >
            OK
          </ModalButton>
        )}
      </ModalFooter>
    </Modal>
  )
}

// ===== Adding colors to selector ===
const ColorSwatch = styled('div', (props: any) => {
  return {
    width: props.$theme.sizing.scale500,
    height: props.$theme.sizing.scale500,
    marginRight: props.$theme.sizing.scale200,
    display: 'inline-block',
    backgroundColor: props.$color,
    verticalAlign: 'baseline',
    ...expandBorderStyles(props.$theme.borders.border400),
  }
})
const getLabel = ({ option }: any) => {
  return (
    <React.Fragment>
      {option.color ? <ColorSwatch $color={option.color} /> : null}
      {option.label}
    </React.Fragment>
  )
}
