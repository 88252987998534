import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useDrawerDispatch, useDrawerState } from 'context/DrawerContext'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { gql, useMutation, useQuery, useReactiveVar } from '@apollo/client'
import Button, { KIND, SHAPE } from 'components/Button/Button'
import {
  ButtonGroup,
  DrawerTitle,
  DrawerTitleWrapper,
  FieldDetails,
  Form,
  RowTitle,
} from 'containers/DrawerItems/DrawerItems.style'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { useForm, Controller, useWatch } from 'react-hook-form'
import { Col, Flex, Grid, Row } from 'components/FlexBox/FlexBox'
import DrawerBox from 'components/DrawerBox/DrawerBox'
import { FormFields, FormLabel } from 'components/FormFields/FormFields'
import Input from 'components/Input/Input'
import { Label4 } from 'baseui/typography'
import { Scrollbars } from 'react-custom-scrollbars'
import { InLineLoader } from 'components/InlineLoader/InlineLoader'
import { ChromePicker } from 'react-color'
import { Checkbox, STYLE_TYPE, LABEL_PLACEMENT } from 'baseui/checkbox'
import { ColorCircleBig } from 'containers/Colors/Colors.style'
import { ColorPicker } from 'components/ColorGradientPicker'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { SiteLanguageVar } from 'lib/reactiveVars'
import ProgressBar from 'components/ProgressBar/ProgressBar'
import { removeKey } from 'utils'
import { DURATION, SnackbarProvider, useSnackbar } from 'baseui/snackbar'
import { ArrowLeft, Check } from 'baseui/icon'
import ColorProfileInput from 'components/LanguageProfiles/ColorProfileInput'
import ManufacturerProfileInput from 'components/LanguageProfiles/ManufacturerProfileInput'
import Uploader from 'components/Uploader/Uploader'
import ShopProfileInput from 'components/LanguageProfiles/ShopProfileInput'
import DefaultPaymentMethodSelect from 'components/PaymentMethodSelect/DefaultPaymentMethodSelect'
import DefaultShippingMethodSelect from 'components/ShippingMethodSelect/DefaultShippingMethodSelect'
import StyleSelect from 'components/StyleSelect/StyleSelect'
import CollectionsSelect from 'components/CollectionsSelect/CollectionsSelect'
import ParentCategorySelect from 'components/CategorySelect/ParentCategorySelect'
import DrawerSelect from 'components/Select/DrawerSelect'
import UploaderBanners from 'components/Uploader/UploaderBanners'
import PolicyProfileInput from 'components/LanguageProfiles/PolicyProfileInput'
import { EditorState, convertFromRaw, convertToRaw } from 'draft-js'
import LanguageSettings from './LanguageSettingsForm'
import { SITE_SETTINGS } from 'settings/constants'
import { useHistory } from 'react-router-dom'
import { Notification, KIND as KINDNOTIFICATION } from 'baseui/notification'
import AboutUsProfileInput from 'components/LanguageProfiles/AboutUsProfileInput'

export const CORE_DESIGN_SETTINGS_DATA = gql`
  fragment CoreB2BSettingsData on ShopSetting {
    id
    b2b_enabled
    b2b_hide_prices_for_all_products
    b2b_redirect_to_login
  }
`

export const UPDATE_B2B_SETTINGS = gql`
  ${CORE_DESIGN_SETTINGS_DATA}
  mutation UPDATE_B2B_SETTINGS(
    $shopSettingsId: Int
    $b2b_enabled: Boolean!
    $b2b_hide_prices_for_all_products: Boolean!
    $b2b_redirect_to_login: Boolean!
  ) {
    updateB2BSettings(
      shopSettingsId: $shopSettingsId
      b2b_enabled: $b2b_enabled
      b2b_hide_prices_for_all_products: $b2b_hide_prices_for_all_products
      b2b_redirect_to_login: $b2b_redirect_to_login
    ) {
      ...CoreB2BSettingsData
    }
  }
`

// We only get the first shop settings, then we update it or create a new if its null.
export const GET_B2B_SETTINGS_DATA = gql`
  ${CORE_DESIGN_SETTINGS_DATA}
  query GET_ABOUT_US_PAGE {
    findFirstShopSetting {
      ...CoreB2BSettingsData
    }
  }
`

// Validation rules....
const schema = yup.object().shape({
  // ----
})

type Props = any
const B2BSettingsForm: React.FC<Props> = () => {
  // const data = useDrawerState("data");
  // const id = data.postID;

  const { enqueue } = useSnackbar()

  //* ------------------------ DRAWER CONTEXT
  const dispatch = useDrawerDispatch()
  // ===== CLOSE DRAWER CALLBACK
  const closeDrawer = useCallback(
    () => dispatch({ type: 'CLOSE_DRAWER' }),
    [dispatch],
  )

  //* ==== Get all the posts data needed by passing the "data.postID" retrieved from Context
  const {
    data: { findFirstShopSetting: shopSettings } = {},
    loading,
    error,
    refetch,
    fetchMore,
  } = useQuery(GET_B2B_SETTINGS_DATA)

  //* ==== Mutation to edita data in database
  const [
    updateAboutUsPage,
    {
      data: mutationData,
      called,
      loading: mutationLoading,
      error: mutationError,
    },
  ] = useMutation(UPDATE_B2B_SETTINGS, {
    onError: (e) => {
      // For mutation errors...
      console.log('Mutation error!', e)
      console.log('Extracted error!', e.graphQLErrors)

      enqueue(
        {
          message: 'Error!',
          startEnhancer: ({ size }) => <Check size={size} />,
          overrides: {
            Message: {
              style: ({ $theme }) => ({
                color: $theme.colors.red400,
              }),
            },
            StartEnhancerContainer: {
              style: ({ $theme }) => ({
                color: $theme.colors.red400,
              }),
            },
          },
        },
        DURATION.short,
      )

      // After mutation is finished - show the updated data...
      // ======== If I need to trigger an error in the profiles tab...
      // setError("profiles", {
      //   type: "manual",
      //   message: "Dont Forget Your Username Should Be Cool!",
      // });
    },
    onCompleted: (e) => {
      // Handle the success case.
      enqueue(
        {
          message: 'Saving succesful!',
          startEnhancer: ({ size }) => <Check size={size} />,
          overrides: {
            Message: {
              style: ({ $theme }) => ({
                color: $theme.colors.lightGreen,
              }),
            },
            StartEnhancerContainer: {
              style: ({ $theme }) => ({
                color: $theme.colors.lightGreen,
              }),
            },
          },
        },
        DURATION.short,
      )
    },
    refetchQueries: ['GET_ABOUT_US_PAGE'],
  })

  //=========== REACT HOOOK FORM ============
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    setError,
    control,
  } = useForm({
    resolver: yupResolver(schema),

    defaultValues: {
      shop_settings_id: null,
      b2b_enabled: false,
      b2b_hide_prices_for_all_products: false,
      b2b_redirect_to_login: false,
    },
  })

  // =================================

  useEffect(() => {
    // Update Hex in state, once Graphql is finished
    if (shopSettings) {
      setValue('shop_settings_id', shopSettings.id ?? null)

      setValue('b2b_enabled', shopSettings.b2b_enabled)
      setValue(
        'b2b_hide_prices_for_all_products',
        shopSettings.b2b_hide_prices_for_all_products,
      )
      setValue('b2b_redirect_to_login', shopSettings.b2b_redirect_to_login)
    }
  }, [setValue, shopSettings]) // We need to include called value (to refresh after mutation)

  const onSubmit = (data) => {
    // Modify form data here =============

    // // Remove __typename keys from the query.
    const modifiedData = removeKey(data, '__typename')
    // ===================================
    console.log('modifiedData (About Us Page) ->', modifiedData)

    updateAboutUsPage({
      variables: {
        shopSettingsId: modifiedData.shop_settings_id,
        b2b_enabled: modifiedData.b2b_enabled,
        b2b_hide_prices_for_all_products:
          modifiedData.b2b_hide_prices_for_all_products,
        b2b_redirect_to_login: modifiedData.b2b_redirect_to_login,
      },
    })
  }

  let history = useHistory()

  //* ===== always manage the loading state, or error will display "TypeError: Cannot read property 'productContainer' of undefined"
  if (loading) return <InLineLoader />
  if (error) return <p>Query Error! {error.message}</p>

  return (
    <>
      <Button
        // shape={SHAPE.circle}
        // startEnhancer={() => <ArrowLeft size={20} />}
        onClick={() => history.push(SITE_SETTINGS)}
        overrides={{
          BaseButton: {
            style: {
              padding: '0 10px',
              marginBottom: '10px',
            },
          },
        }}
      >
        <ArrowLeft size={40} />
      </Button>
      <br></br>
      <DrawerTitle>B2B Settings</DrawerTitle>
      {Object.keys(errors).length > 0 ? (
        <Label4 color="red">There are ERRORS! (scroll up or down)</Label4>
      ) : null}

      <Form onSubmit={handleSubmit(onSubmit)} style={{ height: '100%' }}>
        {/* ==============  VAT ENABLED ? ============== */}
        <Row>
          <Col lg={3} sm={12}>
            <DrawerBox sameHeight noBackground>
              <FormLabel>B2B SYSTEM</FormLabel>
            </DrawerBox>
          </Col>
          <Col lg={9} sm={12}>
            <DrawerBox sameHeight>
              <Row>
                <Col lg={9} sm={12}>
                  <FieldDetails style={{ padding: 0 }}>
                    Enable B2B System site-wide
                  </FieldDetails>
                </Col>

                <Col lg={3} sm={12}>
                  <FormFields>
                    <Controller
                      name="b2b_enabled"
                      control={control}
                      rules={{
                        required: true,
                        shouldUnregister: true,
                      }}
                      render={({ field }) => (
                        <Checkbox
                          checked={field.value}
                          checkmarkType={STYLE_TYPE.toggle_round}
                          onChange={field.onChange}
                          labelPlacement={LABEL_PLACEMENT.left}
                          overrides={{
                            Root: {
                              style: {
                                float: 'right',
                              },
                            },
                          }}
                        />
                      )}
                    />
                  </FormFields>
                </Col>
              </Row>
            </DrawerBox>
          </Col>
        </Row>

        {/* ==============  VAT ENABLED ? ============== */}
        <Row>
          <Col lg={3} sm={12}>
            <DrawerBox sameHeight noBackground>
              <FormLabel>Product Price Settings</FormLabel>
            </DrawerBox>
          </Col>
          <Col lg={9} sm={12}>
            <DrawerBox sameHeight>
              <Row>
                <Col lg={9} sm={12}>
                  <FieldDetails style={{ padding: 0 }}>
                    Show product price only for logged-in B2B customers
                    site-wide
                  </FieldDetails>
                </Col>

                <Col lg={3} sm={12}>
                  <FormFields>
                    <Controller
                      name="b2b_hide_prices_for_all_products"
                      control={control}
                      rules={{
                        required: true,
                        shouldUnregister: true,
                      }}
                      render={({ field }) => (
                        <Checkbox
                          checked={field.value}
                          checkmarkType={STYLE_TYPE.toggle_round}
                          onChange={field.onChange}
                          labelPlacement={LABEL_PLACEMENT.left}
                          overrides={{
                            Root: {
                              style: {
                                float: 'right',
                              },
                            },
                          }}
                        />
                      )}
                    />
                  </FormFields>
                </Col>
              </Row>
            </DrawerBox>
          </Col>
        </Row>
        {/* ==============  VAT ENABLED ? ============== */}
        <Row>
          <Col lg={3} sm={12}>
            <DrawerBox sameHeight noBackground>
              <FormLabel>REDIRECT TO LOGIN</FormLabel>
            </DrawerBox>
          </Col>
          <Col lg={9} sm={12}>
            <DrawerBox sameHeight>
              <Row>
                <Col lg={9} sm={12}>
                  <FieldDetails style={{ padding: 0 }}>
                    Force all visitors to log in before accessing site data
                  </FieldDetails>
                </Col>

                <Col lg={3} sm={12}>
                  <FormFields>
                    <Controller
                      name="b2b_redirect_to_login"
                      control={control}
                      rules={{
                        required: true,
                        shouldUnregister: true,
                      }}
                      render={({ field }) => (
                        <Checkbox
                          checked={field.value}
                          checkmarkType={STYLE_TYPE.toggle_round}
                          onChange={field.onChange}
                          labelPlacement={LABEL_PLACEMENT.left}
                          overrides={{
                            Root: {
                              style: {
                                float: 'right',
                              },
                            },
                          }}
                        />
                      )}
                    />
                  </FormFields>
                </Col>
              </Row>
            </DrawerBox>
          </Col>
        </Row>

        {/* ============== SUBMIT BUTTONS ============== */}

        <Button
          type="submit"
          disabled={mutationError}
          isLoading={mutationLoading}
          overrides={{
            BaseButton: {
              style: ({ $theme }) => ({
                width: '90%',
                margin: '15px 15px 15px 15px',
                // marginTop: "15px",
                // marginBottom: "15px",
                borderTopLeftRadius: '3px',
                borderTopRightRadius: '3px',
                borderBottomRightRadius: '3px',
                borderBottomLeftRadius: '3px',
              }),
            },
          }}
        >
          Save
        </Button>
      </Form>
    </>
  )
}

export default B2BSettingsForm
