import React from "react";
import { Textarea as BaseTextarea, SIZE } from "baseui/textarea";

const getInputFontStyle = ({ $theme }) => {
  return {
    color: $theme.colors.textDark,
    minHeight: "150px",
    ...$theme.typography.fontBold14,
  };
};

export const Textarea = React.forwardRef((props: any, ref) => {
  return (
    <BaseTextarea
      inputRef={ref}
      overrides={{
        Input: {
          style: ({ $theme }) => {
            return {
              ...getInputFontStyle({ $theme }),
            };
          },
        },
      }}
      {...props}
    />
  );
});

export { SIZE };

// export const Textarea = ({ ...props }) => {
//   return (
//     <BaseTextarea
//       overrides={{
//         Input: {
//           style: ({ $theme }) => {
//             return {
//               ...getInputFontStyle({ $theme }),
//             };
//           },
//         },
//       }}
//       {...props}
//     />
//   );
// };
