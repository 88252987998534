import React, { useCallback, useEffect, useState } from 'react'

import { Check } from 'baseui/icon'
import { DURATION } from 'baseui/snackbar'

export const showSuccessNotification = (enqueue, message) => enqueue(
    {
        message,
        startEnhancer: ({ size }) => <Check size={size} />,
        overrides: {
            Message: {
                style: ({ $theme }) => ({
                    color: $theme.colors.lightGreen,
                }),
            },
            StartEnhancerContainer: {
                style: ({ $theme }) => ({
                    color: $theme.colors.lightGreen,
                }),
            },
        },
    },
    DURATION.short
)

export const showErrorNotification = (enqueue, message) => enqueue(
    {
        message,
        startEnhancer: ({ size }) => <Check size={size} />,
        overrides: {
            Message: {
                style: ({ $theme }) => ({
                    color: $theme.colors.red400,
                }),
            },
            StartEnhancerContainer: {
                style: ({ $theme }) => ({
                    color: $theme.colors.red400,
                }),
            },
        },
    },
    DURATION.short
)